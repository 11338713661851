<script>

import SiteHeader from "../components/SiteHeader.vue";
import SiteFooter from "../components/SiteFooter.vue";
import PageNotFoundImg from "../assets/images/svg/pageNotFoundImg.vue";

export default {
    name: "PageNotFound",
    components: {PageNotFoundImg, SiteFooter, SiteHeader}
}

</script>

<template>
    <site-header/>
    <div class="error-page">
        <div class="container">
            <div class="error-page_block">
                <page-not-found-img/>
                <div class="text">
                    <div class="text-title">
                        404
                    </div>
                    <div class="text-desc"
                         v-html="$gettext('Сторінку не знайдено. <br>Спробуйте повернутись на головну')"/>
                </div>
                <router-link :to="{name: 'Main'}" class="button">
                    {{ $gettext('На головну') }}
                </router-link>
            </div>
        </div>
    </div>
    <site-footer/>
</template>

<style lang="scss">
.error-page {
  min-height: calc(100vh - 279px);

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 108px;
    box-sizing: border-box;
  }

  &_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 24px;

      &-title {
        color: #000;
        text-align: center;
        font-size: 72px;
        font-style: normal;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -1.44px;
      }

      &-desc {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.4px;
      }
    }
  }

  .button {
    border-radius: 8px;
    background: #FF7438;
    padding: 16px 32px;
    box-sizing: border-box;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), var(--orange, #FF6B2C);
    }

    @media (max-width: 1079px) {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
