import App from "../App.vue";
import MainPage from "../views/MainPage.vue";
import {getBaseTemplateLang} from "../lang";
import PageNotFound from "../views/PageNotFound.vue";

const base = getBaseTemplateLang();

export default [
    {
        name: 'Local',
        path: base,
        component: App,
        children: [
            {
                path: '',
                name: "Main",
                component: MainPage,
            },
            {
                path: ':pathMatch(.*)*',
                name: '404',
                component: PageNotFound,
                meta: {
                    title: '404',
                    description: '404',
                    reload: true,
                }
            }
        ]
    },
]
