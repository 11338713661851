<script>

import BannerLogoImg from "../../assets/images/svg/bannerLogoImg.vue";

export default {
    name: "BannerBlock",
    components: {BannerLogoImg}
}

</script>

<template>
    <div class="container">
        <div class="banner">
            <div class="banner-text">
                <banner-logo-img class="banner-logo_mob"/>
                <div class="title">
                    {{ $gettext('Semtax - ваш надійний помічник по економічним та юридичним питанням') }}

                </div>
                <div class="desc"
                     v-html="$gettext('Ми надамо вам можливість займатись розвитком бізнесу, <br> а не паперовою рутиною')"/>
                <img
                        class="banner-img"
                        srcset="../../assets/images/png/banner-img2x.png 2x, ../../assets/images/png/banner-img3x.png 3x, ../../assets/images/png/banner-img4x.png 4x"
                        src="../../assets/images/png/banner-img3x.png"
                        alt=""
                />
                <img
                        class="banner-img_mob"
                        srcset="../../assets/images/png/banner-imgMob2x.png 2x, ../../assets/images/png/banner-imgMob3x.png 3x, ../../assets/images/png/banner-imgMob4x.png 4x"
                        src="../../assets/images/png/banner-imgMob3x.png"
                        alt=""
                />
            </div>
        </div>
    </div>


</template>

<style lang="scss">
.banner {
  margin-top: 40px;
  border-radius: 24px;
  background: #015AB4;
  padding: 36px 0 56px 40px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (max-width: 1079px) {
    margin-top: 24px;
  }

  &-img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 544px;

    @media (max-width: 1079px) {
      display: none;
    }

    &_mob {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;

      @media (max-width: 1079px) {
        display: block;
      }
    }
  }

  &-text {
    max-width: 663px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      color: #FFF;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: -0.8px;
    }

    .desc {
      color: rgba(255, 255, 255, 0.64);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.32px;
    }
  }

  &-logo {
    &_mob {
      display: none;

      @media (max-width: 1079px) {
        display: block;
      }
    }
  }

  @media (max-width: 1079px) {
    padding: 136px 44px 32px 24px;
    border-radius: 16px;

    &-text {
      gap: 8px;

      .title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 8px;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
