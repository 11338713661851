import {createApp} from 'vue'
import App from './App.vue'
import Vue3Lottie from 'vue3-lottie'
import {createGettext} from "vue3-gettext";
import translations from "./language/translations.json";
import {getLangFromPath} from "./lang.js";
import {createPinia} from "pinia";
import {createRouter, createWebHistory} from "vue-router";
import routes from './router/index.js';

const pinia = createPinia()

const gettext = createGettext({
    availableLanguages: {
        uk: "Українська",
        en: "English",
        es: "Español",
    },
    defaultLanguage: getLangFromPath(),
    translations: translations,
    silent: true
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App)

app.use(pinia)
app.use(createGettext({ translations }))
app.use(router)
app.use(gettext)
app.use(Vue3Lottie)

app.mount('#app')
