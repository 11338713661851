<script>
export default {
    name: "BurgerIcon"
}
</script>

<template>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_23_5880)">
            <path d="M11.5 29H32.5V26.6667H11.5V29ZM11.5 23.1667H32.5V20.8333H11.5V23.1667ZM11.5 15V17.3333H32.5V15H11.5Z" fill="#015AB4"/>
        </g>
        <defs>
            <clipPath id="clip0_23_5880">
                <rect width="28" height="28" fill="white" transform="translate(8 8)"/>
            </clipPath>
        </defs>
    </svg>
</template>

<style>

</style>
