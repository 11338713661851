<script>

import bankJSON from "../../assets/images/lottie/bank.json"
import calculatorJSON from "../../assets/images/lottie/calculator.json"
import userJSON from "../../assets/images/lottie/user.json"
import lawJSON from "../../assets/images/lottie/law.json"
import {Vue3Lottie} from "vue3-lottie";
import ScrollToMixin from "../../mixins/scroll-to.mixin";
import {ref} from "vue";

export default {
    name: "FeaturesBlock",
    components: {Vue3Lottie},
    mixins: [
        ScrollToMixin
    ],
    setup() {
        const calcAnimate = ref(null);
        const userAnimate = ref(null);
        const bankAnimate = ref(null);
        const lawAnimate = ref(null);
        const play = (ref) => {
            ref.play()
        }

        const stop = (ref) => {
            ref.stop()
        }


        return {
            play,
            stop,
            calcAnimate,
            userAnimate,
            bankAnimate,
            lawAnimate
        }
    },
    data() {
        return {
            bankLottie: bankJSON,
            calculatorLottie: calculatorJSON,
            userLottie: userJSON,
            lawLottie: lawJSON
        }
    },

}

</script>

<template>
    <div class="features">
        <div class="container">
            <div @click="scrollToId('#accountingServices')" class="features--item" @mouseover="play(calcAnimate)"
                 @mouseleave="stop(calcAnimate)">
                <Vue3Lottie ref="calcAnimate" :animation-data="calculatorLottie" :width="82" :height="82"
                            :autoPlay="false"/>
                <div class="text">
                    <div class="title" v-html="$gettext('Бухгалтерські <br>послуги')"/>
                    <div class="desc">
                        {{
                        $gettext('Повний бухгалтерський супровід підприємств, фізичних осіб - підприємців, груп ФОП')
                        }}
                    </div>
                </div>
            </div>
            <div @click="scrollToId('#hrServices')" class="features--item" @mouseover="play(userAnimate)"
                 @mouseleave="stop(userAnimate)">
                <Vue3Lottie ref="userAnimate" :animation-data="userLottie" :width="82" :height="82" :autoPlay="false"/>
                <div class="text">
                    <div class="title" v-html="$gettext('Кадрові <br>послуги')"/>
                    <div class="desc" v-html="$gettext('Кадровий облік згідно КЗпП та управлінських цілей')"/>
                </div>
            </div>
            <div @click="scrollToId('#financialServices')" class="features--item" @mouseover="play(bankAnimate)"
                 @mouseleave="stop(bankAnimate)">
                <Vue3Lottie ref="bankAnimate" :animation-data="bankLottie" :width="82" :height="82" :autoPlay="false"/>
                <div class="text">
                    <div class="title" v-html="$gettext('Фінансові <br>послуги')"/>
                    <div class="desc">
                        {{ $gettext('Відкриття та супровід рахунків в будь-якому банку України та Європи') }}
                    </div>
                </div>
            </div>
            <div @click="scrollToId('#legalServices')" class="features--item" @mouseover="play(lawAnimate)"
                 @mouseleave="stop(lawAnimate)">
                <Vue3Lottie ref="lawAnimate" :animation-data="lawLottie" :width="82" :height="82" :autoPlay="false"/>
                <div class="text">
                    <div class="title" v-html="$gettext('Юридичні <br>послуги')"/>
                    <div class="desc"
                         v-html="$gettext('Вирішення міграційних питань, реєстрація ФОП, внесення змін в реєстраційні данні, ліквідація')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

.features {
  .container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;

    @media (max-width: 1079px) {
      flex-direction: column;
      gap: 6px;
    }
  }

  &--item {
    max-width: 288px;
    padding: 40px 32px 32px;
    box-sizing: border-box;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0 12px 16px 0 rgba(39, 36, 171, 0.08);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      transform: translateY(-12px);
      box-shadow: 0px 12px 24px 0px rgba(39, 36, 171, 0.12);
    }

    .lottie-animation-container {
      margin: 0;
      position: relative;
      left: -10px;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.4px;
        margin-top: 28px;
      }

      .desc {
        color: #8281B1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
      }
    }

    @media (max-width: 1079px) {
      max-width: 100%;
      flex-direction: row-reverse;
      padding: 20px 20px 24px 24px;
      justify-content: space-between;

      svg {
        width: 56px;
      }

      .text {
        max-width: 70%;
        gap: 8px;

        .title {
          margin-top: 0;
        }

        .desc {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
