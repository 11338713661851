<script>

export default {
    name: 'FooterLogoImg'
}

</script>

<template>
    <svg width="190" height="56" viewBox="0 0 190 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M69.9992 39.9896C68.3006 39.9896 66.8371 39.8197 65.6088 39.48C64.4067 39.1141 63.4136 38.6307 62.6296 38.0296C61.8456 37.4024 61.2576 36.6837 60.8656 35.8736C60.4736 35.0373 60.2515 34.1488 60.1992 33.208H66.236C66.3928 33.7829 66.7326 34.2925 67.2552 34.7368C67.7779 35.1549 68.6926 35.364 69.9992 35.364C71.332 35.364 72.2859 35.1811 72.8608 34.8152C73.4358 34.4493 73.7232 33.9659 73.7232 33.3648C73.7232 33.0512 73.5926 32.7768 73.3312 32.5416C73.096 32.2803 72.6387 32.0451 71.9592 31.836C71.3059 31.6269 70.352 31.4179 69.0976 31.2088C67.9478 31.0259 66.8632 30.7907 65.844 30.5032C64.851 30.2157 63.9755 29.8368 63.2176 29.3664C62.4598 28.8699 61.8587 28.2427 61.4144 27.4848C60.9963 26.7269 60.7872 25.7731 60.7872 24.6232C60.7872 23.3949 61.1531 22.2973 61.8848 21.3304C62.6166 20.3635 63.675 19.6056 65.06 19.0568C66.4451 18.4819 68.0915 18.1944 69.9992 18.1944C72.1683 18.1944 73.88 18.5341 75.1344 19.2136C76.415 19.8669 77.3558 20.7163 77.9568 21.7616C78.5579 22.7808 78.9107 23.8523 79.0152 24.976H72.9392C72.7824 24.3227 72.4427 23.8 71.92 23.408C71.4235 23.016 70.7832 22.82 69.9992 22.82C68.7971 22.82 67.9608 23.0029 67.4904 23.3688C67.0462 23.7347 66.824 24.1789 66.824 24.7016C66.824 25.2243 67.1899 25.6424 67.9216 25.956C68.6534 26.2435 69.8163 26.4917 71.4104 26.7008C72.5603 26.8576 73.6318 27.0797 74.6248 27.3672C75.644 27.6547 76.5326 28.0597 77.2904 28.5824C78.0744 29.0789 78.6886 29.7192 79.1328 30.5032C79.5771 31.2611 79.7992 32.1888 79.7992 33.2864C79.7992 34.5931 79.4203 35.756 78.6624 36.7752C77.9307 37.7944 76.8331 38.5915 75.3696 39.1664C73.9323 39.7152 72.1422 39.9896 69.9992 39.9896Z" fill="black"/>
        <path d="M90.9544 39.9896C88.916 39.9896 87.0866 39.6107 85.4664 38.8528C83.8461 38.0688 82.5525 36.8797 81.5856 35.2856C80.6448 33.6915 80.1744 31.6923 80.1744 29.288V28.504C80.1744 26.2043 80.6317 24.2965 81.5464 22.7808C82.461 21.2651 83.7024 20.1283 85.2704 19.3704C86.8645 18.5864 88.6285 18.1944 90.5624 18.1944C93.9858 18.1944 96.573 19.0829 98.324 20.86C100.075 22.6109 100.95 25.1589 100.95 28.504V30.6992H86.368C86.4202 31.7184 86.6554 32.5677 87.0736 33.2472C87.5178 33.9267 88.0797 34.4232 88.7592 34.7368C89.4386 35.0243 90.1704 35.168 90.9544 35.168C92.1042 35.168 93.0058 34.9981 93.6592 34.6584C94.3386 34.2925 94.7829 33.8091 94.992 33.208H100.558C100.14 35.4816 99.108 37.1803 97.4616 38.304C95.8152 39.4277 93.6461 39.9896 90.9544 39.9896ZM90.7584 23.016C90.0266 23.016 89.3341 23.1336 88.6808 23.3688C88.0274 23.604 87.4917 23.996 87.0736 24.5448C86.6816 25.0675 86.4594 25.7861 86.4072 26.7008H95.0312C95.0312 25.7339 94.8352 24.9891 94.4432 24.4664C94.0512 23.9176 93.5285 23.5387 92.8752 23.3296C92.248 23.1205 91.5424 23.016 90.7584 23.016Z" fill="black"/>
        <path d="M102.12 39.48V18.704H107.804V21.6832C108.275 20.7685 109.006 19.9584 109.999 19.2528C110.993 18.5472 112.221 18.1944 113.684 18.1944C115.331 18.1944 116.598 18.508 117.487 19.1352C118.401 19.7624 119.068 20.6117 119.486 21.6832C120.009 20.6901 120.819 19.8669 121.916 19.2136C123.014 18.5341 124.307 18.1944 125.797 18.1944C128.045 18.1944 129.756 18.8347 130.932 20.1152C132.108 21.3696 132.696 23.2512 132.696 25.76V39.48H126.659V27.2496C126.659 25.7861 126.424 24.7539 125.954 24.1528C125.51 23.5256 124.778 23.212 123.759 23.212C123.158 23.212 122.596 23.3819 122.073 23.7216C121.577 24.0352 121.171 24.4925 120.858 25.0936C120.57 25.6947 120.427 26.4133 120.427 27.2496V39.48H114.39V27.2496C114.39 25.7861 114.155 24.7539 113.684 24.1528C113.24 23.5256 112.508 23.212 111.489 23.212C110.862 23.212 110.3 23.3819 109.803 23.7216C109.307 24.0352 108.902 24.4925 108.588 25.0936C108.301 25.6947 108.157 26.4133 108.157 27.2496V39.48H102.12Z" fill="black"/>
        <path d="M143.28 39.7152C141.555 39.7152 140.144 39.4931 139.047 39.0488C137.949 38.5784 137.139 37.8205 136.616 36.7752C136.094 35.7037 135.832 34.2533 135.832 32.424V23.6432H133.284V18.704H135.832V14H141.869V18.704H146.612V23.6432H141.869V31.444C141.869 32.62 142.052 33.4563 142.418 33.9528C142.81 34.4493 143.555 34.6976 144.652 34.6976C145.436 34.6976 146.22 34.6584 147.004 34.58V39.48C146.508 39.5584 145.985 39.6107 145.436 39.6368C144.887 39.6891 144.169 39.7152 143.28 39.7152Z" fill="black"/>
        <path d="M154.664 39.9896C153.174 39.9896 151.893 39.7021 150.822 39.1272C149.777 38.5523 148.98 37.7683 148.431 36.7752C147.882 35.756 147.608 34.6323 147.608 33.404C147.608 31.3395 148.235 29.7584 149.489 28.6608C150.77 27.5632 152.691 27.0144 155.252 27.0144H161.132V26.152C161.132 24.8976 160.831 24.0352 160.23 23.5648C159.655 23.0944 158.845 22.8592 157.8 22.8592C156.807 22.8592 156.075 23.016 155.604 23.3296C155.134 23.6171 154.873 24.1005 154.82 24.78H148.784C148.862 23.5256 149.254 22.4019 149.96 21.4088C150.691 20.4157 151.724 19.6317 153.056 19.0568C154.415 18.4819 156.062 18.1944 157.996 18.1944C159.825 18.1944 161.432 18.4688 162.817 19.0176C164.202 19.5664 165.274 20.4549 166.032 21.6832C166.816 22.8853 167.208 24.5056 167.208 26.544V34.188C167.208 35.2856 167.234 36.2525 167.286 37.0888C167.338 37.8989 167.417 38.696 167.521 39.48H161.759C161.733 39.0357 161.693 38.6437 161.641 38.304C161.615 37.9643 161.602 37.5461 161.602 37.0496C160.975 37.8859 160.099 38.5915 158.976 39.1664C157.878 39.7152 156.441 39.9896 154.664 39.9896ZM156.624 35.7168C157.486 35.7168 158.257 35.56 158.936 35.2464C159.616 34.9328 160.152 34.4624 160.544 33.8352C160.936 33.1819 161.132 32.4501 161.132 31.64V30.7776H156.428C155.539 30.7776 154.873 31.0128 154.428 31.4832C154.01 31.9536 153.801 32.4893 153.801 33.0904C153.801 33.8744 154.01 34.5147 154.428 35.0112C154.873 35.4816 155.604 35.7168 156.624 35.7168Z" fill="black"/>
        <path d="M167.407 39.48L174.031 28.6608L167.799 18.704H174.463L178.539 25.0152L182.459 18.704H188.967L182.773 28.4648L189.359 39.48H182.655L178.304 32.0712L173.953 39.48H167.407Z" fill="black"/>
        <mask id="mask0_103_1300" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
            <rect width="56" height="56" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_103_1300)">
            <path d="M46.5002 25.3489C46.5002 25.5926 46.5008 25.8303 46.502 26.0625C46.4131 25.3115 46.245 24.7262 45.9449 24.2154C45.6187 23.6603 45.1363 23.1931 44.4307 22.6963L39.8041 20.0251C39.0125 19.5871 38.1308 19.0933 37.1475 18.5257C36.1644 17.9582 35.2961 17.4414 34.5212 16.9751L29.893 14.3029C29.0889 13.9305 28.4293 13.7467 27.7679 13.7518C27.0953 13.7569 26.4205 13.9576 25.5892 14.3536L21.5132 16.7068C20.6295 17.2409 19.6175 17.8469 18.4418 18.5259C17.266 19.2047 16.2352 19.7778 15.331 20.2761L11.254 22.63C10.4759 23.1655 9.95839 23.6614 9.61774 24.262C9.33436 24.7617 9.17351 25.3338 9.0874 26.0619C9.08844 25.8299 9.08916 25.5922 9.08897 25.3489C9.08902 24.3827 9.07937 23.5071 9.06645 22.7101L9.06651 16.8793C9.14671 16.0577 9.30979 15.4285 9.61793 14.8855C9.95848 14.2851 10.476 13.7893 11.2542 13.2536L15.3314 10.8997C16.2357 10.4013 17.2666 9.82827 18.4422 9.1494C19.618 8.47064 20.63 7.86442 21.5136 7.33041L25.5895 4.97722C26.4207 4.58147 27.0957 4.38059 27.7683 4.37547C28.4297 4.37039 29.0892 4.55411 29.8933 4.92659L34.5216 7.59871C35.2965 8.06507 36.1647 8.58181 37.1478 9.14939C38.1309 9.71698 39.0129 10.2107 39.8046 10.6489L44.4313 13.3201C45.1369 13.8169 45.6192 14.284 45.9453 14.8391C46.2704 15.3924 46.4407 16.0333 46.5232 16.8772L46.5231 22.7286C46.5103 23.5208 46.5008 24.3903 46.5008 25.349" fill="#003BAF"/>
            <path d="M13.8135 21.1523L11.2534 22.6302C10.6105 23.0729 10.1453 23.4885 9.811 23.9581C9.55522 23.8952 9.30973 23.8014 9.07923 23.6805C9.0755 23.3448 9.07093 23.0215 9.0659 22.7103L9.0658 17.9383L9.08672 17.9272C10.2165 19.5425 11.8975 20.6892 13.8135 21.152" fill="#002D85"/>
            <path d="M45.9992 24.3126C45.9814 24.2801 45.9633 24.2478 45.9445 24.2159C45.6183 23.6608 45.136 23.1935 44.4305 22.6969L41.0837 20.7647C43.2631 20.8369 45.3202 19.7595 46.5018 17.9271L46.5223 17.9399L46.5223 22.7289C46.5156 23.1501 46.5096 23.593 46.5057 24.0596C46.3438 24.1571 46.1746 24.2418 45.9993 24.3127" fill="#002D85"/>
            <path d="M9.56787 18.5489L9.56777 16.9043C9.6289 16.2929 9.74744 15.6736 10.0539 15.1336C10.3985 14.5257 10.9545 14.0706 11.5218 13.6788L15.577 11.3375C16.6206 10.7625 17.6601 10.1806 18.6921 9.58475C19.7243 8.98882 20.7479 8.37948 21.768 7.76321L25.8222 5.42252C26.4303 5.13417 27.0895 4.88349 27.771 4.87807C28.4333 4.87305 29.0667 5.0997 29.661 5.37331L34.2653 8.03165C35.1378 8.5569 36.0137 9.07546 36.8957 9.58466C37.7776 10.0939 38.6653 10.5932 39.5563 11.0864L44.1593 13.7439C44.6802 14.1121 45.1844 14.5375 45.5113 15.0938C45.8343 15.6435 45.9571 16.275 46.0198 16.9022L46.0198 18.577C45.7979 18.8389 45.5561 19.0798 45.2973 19.2989C44.984 18.8921 44.5769 18.5601 44.1593 18.2647L39.5563 15.6072C38.6653 15.1141 37.7778 14.6148 36.8957 14.1055C36.0135 13.5962 35.1379 13.0775 34.2655 12.5526L29.661 9.89412C29.0668 9.62033 28.4334 9.39368 27.7711 9.39871C27.0895 9.40402 26.4304 9.6548 25.8221 9.94305L21.7679 12.2837C20.7478 12.9 19.7242 13.5093 18.6921 14.1051C17.6601 14.7011 16.6206 15.2828 15.577 15.8579L11.5217 18.1993C11.0723 18.5098 10.63 18.86 10.294 19.2945C10.0368 19.0624 9.79439 18.8134 9.56789 18.5485" fill="url(#paint0_linear_103_1300)"/>
            <path d="M46.4992 18.6405C46.4993 18.3968 46.5 18.1592 46.5009 17.9269C46.412 18.6777 46.2439 19.2632 45.9439 19.7739C45.6176 20.3289 45.1353 20.7962 44.4298 21.2929L39.8032 23.9641C39.0117 24.4022 38.1298 24.8958 37.1466 25.4636C36.1635 26.0312 35.2953 26.5479 34.5202 27.0142L29.8919 29.6864C29.0877 30.0588 28.4283 30.2427 27.767 30.2375C27.0942 30.2323 26.4194 30.0316 25.588 29.6358L21.5123 27.2827C20.6285 26.7486 19.6167 26.1424 18.4408 25.4635C17.265 24.7846 16.2343 24.2115 15.3299 23.7133L11.2527 21.3593C10.4747 20.8237 9.95715 20.3279 9.61656 19.7274C9.33324 19.2278 9.17251 18.6556 9.08617 17.9274C9.08722 18.1595 9.08794 18.397 9.08787 18.6404C9.08791 19.6064 9.07831 20.4822 9.06526 21.2791L9.06527 22.7102L9.06537 27.1101C9.14546 27.9318 9.30878 28.5607 9.61683 29.1039C9.95731 29.7045 10.4751 30.2003 11.253 30.7358L15.3301 33.0897C16.2346 33.588 17.2653 34.1611 18.4409 34.8399C19.6168 35.5187 20.6286 36.1249 21.5124 36.659L25.5881 39.0122C26.4195 39.4079 27.0943 39.6086 27.7669 39.6138C28.4283 39.6188 29.0878 39.4352 29.892 39.0627L34.5203 36.3906C35.2953 35.9243 36.1636 35.4076 37.1467 34.84C38.1301 34.2723 39.0118 33.7786 39.8033 33.3405L44.4301 30.6693C45.1356 30.1725 45.6179 29.7054 45.9442 29.1504C46.2693 28.597 46.4395 27.9563 46.522 27.1124L46.522 22.7287L46.5219 21.2609C46.5089 20.4688 46.4996 19.5992 46.4995 18.6404" fill="#FF6B2C"/>
            <path d="M9.56869 22.5648L9.5687 27.0856C9.62972 27.6971 9.7483 28.3163 10.0547 28.8563C10.3994 29.4642 10.9552 29.9192 11.5226 30.3109L15.5778 32.6522C16.6216 33.2274 17.6609 33.809 18.693 34.4049C19.7251 35.0007 20.7489 35.6101 21.7688 36.2266L25.8228 38.5672C26.4311 38.8556 27.0902 39.1065 27.7717 39.1116C28.434 39.1167 29.0673 38.8901 29.6615 38.6164L34.2661 35.958C35.1385 35.4329 36.0145 34.9142 36.8965 34.405C37.7785 33.8957 38.666 33.3963 39.5572 32.9032L44.1602 30.2457C44.681 29.8774 45.1853 29.4521 45.5121 28.8957C45.8351 28.346 45.9579 27.7145 46.0206 27.0874L46.0206 22.5666C45.9579 23.1937 45.8349 23.8254 45.5121 24.3749C45.1852 24.931 44.681 25.3566 44.1601 25.7249L39.5572 28.3824C38.666 28.8755 37.7787 29.3749 36.8966 29.8843C36.0148 30.3933 35.1388 30.9121 34.2664 31.4371L29.6619 34.0956C29.0678 34.3694 28.4342 34.596 27.7721 34.5908C27.0906 34.5854 26.4313 34.3347 25.8231 34.0463L21.7691 31.7057C20.7491 31.0894 19.7255 30.48 18.6933 29.884C17.661 29.288 16.6219 28.7064 15.5781 28.1313L11.5227 25.7899C10.9554 25.398 10.3996 24.943 10.0548 24.3353C9.74849 23.7951 9.62991 23.1759 9.56879 22.5646" fill="url(#paint1_linear_103_1300)"/>
            <path d="M28.5915 42.5418C28.3101 42.6035 28.0385 42.6332 27.7667 42.6313C27.5129 42.6294 27.2588 42.5995 26.9962 42.542C26.0577 42.4259 25.1776 42.1144 24.3081 41.7004L24.2014 41.6496L19.9977 39.2226L19.9722 39.2071C18.9706 38.6019 17.9655 38.0034 16.9519 37.4182C15.9383 36.833 14.9177 36.2618 13.8928 35.6972L13.8667 35.6829L9.66157 33.255L9.56423 33.188C9.39774 33.0734 9.23503 32.9563 9.07672 32.8357C9.07329 33.1242 9.06926 33.4032 9.06497 33.6729L9.06498 35.104L9.06508 39.5039C9.14517 40.3256 9.30849 40.9545 9.61654 41.4977C9.95702 42.0983 10.4746 42.594 11.2527 43.1296L15.3298 45.4835C16.2343 45.9818 17.265 46.5549 18.4406 47.2337C19.6163 47.9124 20.6283 48.5187 21.5121 49.0528L25.5878 51.406C26.4191 51.8019 27.094 52.0024 27.7668 52.0077C28.4282 52.0127 29.0877 51.8291 29.8919 51.4566L34.5202 48.7845C35.2952 48.3182 36.1635 47.8015 37.1466 47.2339C38.13 46.6662 39.0118 46.1723 39.8032 45.7344L44.4298 43.0631C45.1355 42.5664 45.6176 42.0992 45.9439 41.5442C46.269 40.9908 46.4392 40.3501 46.5217 39.5062L46.5217 35.1225L46.5216 33.6546C46.5173 33.3905 46.5133 33.1176 46.5102 32.8356C46.3903 32.9269 46.268 33.0162 46.1435 33.1038L46.0342 33.1808L41.2683 35.9324L41.2446 35.9455C40.3706 36.4291 39.5001 36.9189 38.635 37.4183C37.77 37.9176 36.9109 38.4265 36.0553 38.9416L36.032 38.9555L31.2644 41.708L31.1431 41.7641C30.3146 42.1479 29.4778 42.432 28.5915 42.5418Z" fill="#FF6B2C"/>
            <path d="M9.56774 34.9584L9.56775 39.4792C9.62877 40.0907 9.74745 40.7097 10.0538 41.2499C10.3985 41.8578 10.9546 42.3127 11.5217 42.7048L15.5769 45.0461C16.6208 45.6211 17.6601 46.2029 18.6921 46.7987C19.7242 47.3946 20.748 48.004 21.7679 48.6204L25.822 50.961C26.4302 51.2495 27.0895 51.5002 27.7708 51.5055C28.433 51.5107 29.0665 51.284 29.6606 51.0102L34.2652 48.3519C35.1376 47.8268 36.0136 47.3081 36.8955 46.799C37.7775 46.2898 38.665 45.7903 39.5562 45.2972L44.1592 42.6397C44.68 42.2715 45.1843 41.8461 45.5111 41.2898C45.8341 40.7401 45.9569 40.1086 46.0196 39.4814L46.0196 34.9606C45.9569 35.5878 45.8341 36.2193 45.5111 36.769C45.1841 37.3252 44.68 37.7507 44.1592 38.1189L39.5562 40.7764C38.665 41.2695 37.7777 41.7689 36.8958 42.2781C36.0139 42.7872 35.1379 43.3059 34.2654 43.8312L29.6609 46.4896C29.0668 46.7634 28.4334 46.9901 27.7712 46.9849C27.0895 46.9797 26.4305 46.7289 25.8221 46.4403L21.7681 44.0997C20.7482 43.4833 19.7245 42.874 18.6923 42.278C17.6601 41.6821 16.621 41.1004 15.5771 40.5254L11.5217 38.184C10.9544 37.7921 10.3986 37.3371 10.0539 36.7292C9.74762 36.189 9.62903 35.5698 9.56791 34.9585" fill="url(#paint2_linear_103_1300)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_103_1300" x1="42.2126" y1="25.1713" x2="13.747" y2="8.73671" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0056FF"/>
                <stop offset="1" stop-color="#0056FF" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_103_1300" x1="42.3909" y1="34.3019" x2="12.6919" y2="17.1551" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFAB2C"/>
                <stop offset="1" stop-color="#FF6B2C"/>
            </linearGradient>
            <linearGradient id="paint2_linear_103_1300" x1="42.39" y1="46.6958" x2="12.6908" y2="29.549" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFAB2C"/>
                <stop offset="1" stop-color="#FF6B2C"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<style scoped>

</style>
