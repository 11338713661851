<script>
export default {
    name: "LinkedInIcon"
}
</script>

<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24_9000)">
            <path d="M2.79324 3.86035H0.384222C0.277306 3.86035 0.190674 3.94702 0.190674 4.0539V11.793C0.190674 11.9 0.277306 11.9866 0.384222 11.9866H2.79324C2.90016 11.9866 2.98679 11.9 2.98679 11.793V4.0539C2.98679 3.94702 2.90016 3.86035 2.79324 3.86035Z" fill="#015AB4"/>
            <path d="M1.58965 0.0131836C0.71311 0.0131836 0 0.725519 0 1.60109C0 2.47705 0.71311 3.18966 1.58965 3.18966C2.4655 3.18966 3.17803 2.47702 3.17803 1.60109C3.17806 0.725519 2.4655 0.0131836 1.58965 0.0131836Z" fill="#015AB4"/>
            <path d="M8.92087 3.66797C7.95332 3.66797 7.23808 4.0839 6.80426 4.55651V4.05387C6.80426 3.94699 6.71763 3.86032 6.61071 3.86032H4.30366C4.19674 3.86032 4.11011 3.94699 4.11011 4.05387V11.793C4.11011 11.8999 4.19674 11.9866 4.30366 11.9866H6.70741C6.81433 11.9866 6.90096 11.8999 6.90096 11.793V7.96393C6.90096 6.67362 7.25144 6.17094 8.15089 6.17094C9.13048 6.17094 9.20833 6.97679 9.20833 8.03032V11.7931C9.20833 11.9 9.29496 11.9866 9.40188 11.9866H11.8065C11.9134 11.9866 12.0001 11.9 12.0001 11.7931V7.54799C12.0001 5.62935 11.6342 3.66797 8.92087 3.66797Z" fill="#015AB4"/>
        </g>
        <defs>
            <clipPath id="clip0_24_9000">
                <rect width="12" height="12" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped>

</style>
