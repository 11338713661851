<script >
export default {
    name: "FacebookIcon"
}
</script>

<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99961 0.0024968L7.44347 0C5.69521 0 4.56541 1.15914 4.56541 2.95322V4.31485H3.00079C2.86559 4.31485 2.7561 4.42446 2.7561 4.55966V6.53251C2.7561 6.66771 2.86571 6.77719 3.00079 6.77719H4.56541V11.7553C4.56541 11.8905 4.6749 12 4.8101 12H6.85148C6.98668 12 7.09617 11.8904 7.09617 11.7553V6.77719H8.92558C9.06078 6.77719 9.17026 6.66771 9.17026 6.53251L9.17101 4.55966C9.17101 4.49474 9.14517 4.43257 9.09935 4.38663C9.05354 4.34069 8.99112 4.31485 8.9262 4.31485H7.09617V3.16058C7.09617 2.60579 7.22838 2.32415 7.95107 2.32415L8.99936 2.32377C9.13443 2.32377 9.24392 2.21416 9.24392 2.07909V0.247183C9.24392 0.112231 9.13456 0.00274648 8.99961 0.0024968Z" fill="#015AB4"/>
    </svg>
</template>

<style scoped>

</style>
