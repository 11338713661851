<script>
export default {
    name: "FeaturesItem1"
}

</script>

<template>
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57.4438 29.1123H14.5562C13.7019 29.1123 13.0094 28.4197 13.0094 27.5654V16.1287C13.0094 15.2744 13.7019 14.5818 14.5562 14.5818H57.4439C58.2982 14.5818 58.9908 15.2744 58.9908 16.1287V27.5654C58.9906 28.4197 58.2981 29.1123 57.4438 29.1123ZM16.1031 26.0185H55.897V17.6755H16.1031V26.0185Z" fill="black"/>
        <path d="M57.5838 0H14.4162C8.91084 0 4.4318 4.47905 4.4318 9.98438V62.0156C4.4318 67.521 8.91084 72 14.4162 72H57.584C63.0893 72 67.5683 67.521 67.5683 62.0156V9.98438C67.5682 4.47905 63.0893 0 57.5838 0ZM64.4744 62.0156C64.4744 65.8152 61.3834 68.9062 57.5838 68.9062H14.4162C10.6166 68.9062 7.52555 65.8152 7.52555 62.0156V9.98438C7.52555 6.18483 10.6166 3.09375 14.4162 3.09375H57.584C61.3835 3.09375 64.4746 6.18483 64.4746 9.98438V62.0156H64.4744Z" fill="#FF6B2C"/>
        <path d="M23.1337 34.5962H14.5562C13.7019 34.5962 13.0094 35.2888 13.0094 36.1431V44.7206C13.0094 45.5749 13.7019 46.2675 14.5562 46.2675H23.1337C23.988 46.2675 24.6805 45.5749 24.6805 44.7206V36.1431C24.6805 35.2886 23.988 34.5962 23.1337 34.5962ZM21.5868 43.1736H16.1031V37.6898H21.5868V43.1736Z" fill="#FF6B2C"/>
        <path d="M23.1337 51.7512H14.5562C13.7019 51.7512 13.0094 52.4438 13.0094 53.2981V61.8757C13.0094 62.73 13.7019 63.4225 14.5562 63.4225H23.1337C23.988 63.4225 24.6805 62.73 24.6805 61.8757V53.2981C24.6805 52.4438 23.988 51.7512 23.1337 51.7512ZM21.5868 60.3288H16.1031V54.845H21.5868V60.3288Z" fill="#FF6B2C"/>
        <path d="M57.4438 34.5962H48.8663C48.0121 34.5962 47.3195 35.2888 47.3195 36.1431V61.8756C47.3195 62.7299 48.0121 63.4225 48.8663 63.4225H57.4438C58.2981 63.4225 58.9906 62.7299 58.9906 61.8756V36.1431C58.9906 35.2886 58.2981 34.5962 57.4438 34.5962ZM55.8969 60.3287H50.4132V37.6899H55.8969V60.3287Z" fill="#FF6B2C"/>
        <path d="M40.2888 34.5962H31.7112C30.8569 34.5962 30.1643 35.2888 30.1643 36.1431V44.7206C30.1643 45.5749 30.8569 46.2675 31.7112 46.2675H40.2888C41.1431 46.2675 41.8357 45.5749 41.8357 44.7206V36.1431C41.8357 35.2886 41.1431 34.5962 40.2888 34.5962ZM38.7419 43.1736H33.2581V37.6898H38.7419V43.1736Z" fill="#FF6B2C"/>
        <path d="M40.2888 51.7512H31.7112C30.8569 51.7512 30.1643 52.4438 30.1643 53.2981V61.8757C30.1643 62.73 30.8569 63.4225 31.7112 63.4225H40.2888C41.1431 63.4225 41.8357 62.73 41.8357 61.8757V53.2981C41.8357 52.4438 41.1431 51.7512 40.2888 51.7512ZM38.7419 60.3288H33.2581V54.845H38.7419V60.3288Z" fill="#FF6B2C"/>
        <path d="M40.2888 7.29102H31.7112C29.6616 7.37145 29.6578 10.3026 31.7112 10.3848H40.2888C41.1431 10.3848 41.8357 9.69219 41.8357 8.83789C41.8357 7.98359 41.1431 7.29102 40.2888 7.29102Z" fill="#FF6B2C"/>
    </svg>
</template>

<style scoped>

</style>
