<script>
import ServicesItem1 from "../../assets/images/svg/servicesItem1.vue";
import ServicesItem2 from "../../assets/images/svg/servicesItem2.vue";
import ServicesItem3 from "../../assets/images/svg/servicesItem3.vue";
import ServicesItem4 from "../../assets/images/svg/servicesItem4.vue";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToMixin from "../../mixins/scroll-to.mixin";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: "ServicesBlock",
    components: {ServicesItem4, ServicesItem3, ServicesItem2, ServicesItem1},
    mixins: [
        ScrollToMixin
    ],
    mounted() {
        this.animateHandler()
    },
    methods: {
        animateHandler() {
            gsap.from(['#accountingServices', '#financialServices'], {
                scrollTrigger: {
                    trigger: ".services",
                    start: "-10% 50%",
                    end: "70% 50%",
                },

                opacity: 0,
                x: 800,
                stagger: 0.7,
                duration: 1,
                ease: "circ"
            })
            gsap.from(['#hrServices', '#legalServices'], {
                scrollTrigger: {
                    trigger: ".services",
                    start: "10% 50%",
                    end: "70% 50%",
                },

                opacity: 0,
                x: -800,
                delay: 0.7,
                stagger: 0.7,
                duration: 1,
                ease: "circ"
            })
        }
    }
}

</script>

<template>
    <div class="services">
        <div class="container">
            <div id="accountingServices" class="services--item">
                <services-item1/>
                <div class="text">
                    <div class="title">
                        {{ $gettext('Бухгалтерські послуги') }}
                    </div>
                    <div class="desc">
                        {{ $gettext('Ми товаришуємо з податковим кодексом і клієнт-банком') }}
                    </div>
                    <div class="list">
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_656)">
                                        <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM8.82 13.05L7.4 14.46L10.94 18L16.6 12.34L15.19 10.93L10.95 15.17L8.82 13.05Z"
                                              fill="#F15A1A"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_656">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Підготовка первинної документації') }}

                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_663)">
                                        <path d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5ZM19 19.09H5V4.91H19V19.09ZM6 15H18V17H6V15ZM6 11H18V13H6V11ZM6 7H18V9H6V7Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_663">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                            <span>
                                {{ $gettext('Розрахунок і сплата податків') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_668)">
                                        <path d="M7 15H14V17H7V15ZM7 11H17V13H7V11ZM7 7H17V9H7V7ZM19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.86 3 4.73 3.01 4.6 3.04C4.21 3.12 3.86 3.32 3.59 3.59C3.41 3.77 3.26 3.99 3.16 4.23C3.06 4.46 3 4.72 3 5V19C3 19.27 3.06 19.54 3.16 19.78C3.26 20.02 3.41 20.23 3.59 20.42C3.86 20.69 4.21 20.89 4.6 20.97C4.73 20.99 4.86 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 2.75C12.41 2.75 12.75 3.09 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25C11.59 4.25 11.25 3.91 11.25 3.5C11.25 3.09 11.59 2.75 12 2.75ZM19 19H5V5H19V19Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_668">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Формування і подача звітності') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_673)">
                                        <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V8H18V20H6ZM11 19H13V18H14C14.55 18 15 17.55 15 17V14C15 13.45 14.55 13 14 13H11V12H15V10H13V9H11V10H10C9.45 10 9 10.45 9 11V14C9 14.55 9.45 15 10 15H13V16H9V18H11V19Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_673">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Ведення книги обліку доходів (відновлення книги)') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_679)">
                                        <path d="M6.5 10H4.5V17H6.5V10ZM12.5 10H10.5V17H12.5V10ZM21 19H2V21H21V19ZM18.5 10H16.5V17H18.5V10ZM11.5 3.26L16.71 6H6.29L11.5 3.26ZM11.5 1L2 6V8H21V6L11.5 1Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_679">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Робота з системою клієнт-банк') }}
                          </span>
                        </div>
                    </div>
                    <div @click="scrollToId('#contacts')" class="button">
                        {{ $gettext('Отримати консультацію') }}
                    </div>
                </div>
            </div>
            <div id="hrServices" class="services--item services--item-reversed">
                <services-item2/>
                <div class="text">
                    <div class="title">
                        {{ $gettext('Кадрові послуги') }}
                    </div>
                    <div class="desc">
                        {{
                        $gettext('Ми захистимо вас від найбільших штрафних санкцій, які можливі в підприємницькій діяльності')
                        }}
                    </div>
                    <div class="list">
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_692)">
                                        <path d="M20 7H16V5L14 3H10L8 5V7H4C2.9 7 2 7.9 2 9V14C2 14.75 2.4 15.38 3 15.73V19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V15.72C21.59 15.37 22 14.73 22 14V9C22 7.9 21.1 7 20 7ZM10 5H14V7H10V5ZM4 9H20V14H15V11H9V14H4V9ZM13 15H11V13H13V15ZM19 19H5V16H9V17H15V16H19V19Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_692">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                            <span>
                                {{ $gettext('Складання на оформлення трудових договорів') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_697)">
                                        <path d="M21 5H23V19H21V5ZM17 5H19V19H17V5ZM14 5H2C1.45 5 1 5.45 1 6V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V6C15 5.45 14.55 5 14 5ZM13 17H3V7H13V17Z"
                                              fill="#FF6B2C"/>
                                        <path d="M8 11.89C9.07695 11.89 9.95 11.0169 9.95 9.93999C9.95 8.86303 9.07695 7.98999 8 7.98999C6.92304 7.98999 6.05 8.86303 6.05 9.93999C6.05 11.0169 6.92304 11.89 8 11.89Z"
                                              fill="#FF6B2C"/>
                                        <path d="M11.89 15.35C11.89 14.05 9.3 13.4 8 13.4C6.7 13.4 4.11 14.05 4.11 15.35V16H11.89V15.35Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_697">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Формування та подання звітності по кадрам') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_704)">
                                        <path d="M6 20V10H18V11C18.7 11 19.37 11.1 20 11.29V10C20 8.9 19.1 8 18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H12.26C11.84 21.4 11.51 20.72 11.29 20H6ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6Z"
                                              fill="#FF6B2C"/>
                                        <path d="M18 13C15.24 13 13 15.24 13 18C13 20.76 15.24 23 18 23C20.76 23 23 20.76 23 18C23 15.24 20.76 13 18 13ZM18 15C18.83 15 19.5 15.67 19.5 16.5C19.5 17.33 18.83 18 18 18C17.17 18 16.5 17.33 16.5 16.5C16.5 15.67 17.17 15 18 15ZM18 21C16.97 21 16.06 20.48 15.52 19.68C16.25 19.26 17.09 19 18 19C18.91 19 19.75 19.26 20.48 19.68C19.94 20.48 19.03 21 18 21Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_704">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Розробка і оформлення NDA (згод про нерозголошення)') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_713)">
                                        <path d="M12 10C14.21 10 16 8.21 16 6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6C8 8.21 9.79 10 12 10ZM12 4C13.1 4 14 4.9 14 6C14 7.1 13.1 8 12 8C10.9 8 10 7.1 10 6C10 4.9 10.9 4 12 4ZM18.39 12.56C16.71 11.7 14.53 11 12 11C9.47 11 7.29 11.7 5.61 12.56C4.61 13.07 4 14.1 4 15.22V22H6V15.22C6 14.84 6.2 14.5 6.52 14.34C7.71 13.73 9.63 13 12 13C12.76 13 13.47 13.07 14.13 13.2L12.58 16.5H9.75C8.23 16.5 7 17.73 7 19.25C7 20.77 8.23 22 9.75 22H11.93H18C19.1 22 20 21.1 20 20V15.22C20 14.1 19.39 13.07 18.39 12.56ZM10.94 20H9.75C9.34 20 9 19.66 9 19.25C9 18.84 9.34 18.5 9.75 18.5H11.64L10.94 20ZM18 20H13.15L16.09 13.73C16.63 13.93 17.1 14.14 17.49 14.34C17.8 14.5 18 14.84 18 15.22V20Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_713">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Розрахунок та документальне оформлення лікарняних та відпусток') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_718)">
                                        <path d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3ZM18.82 9L12 12.72L5.18 9L12 5.28L18.82 9ZM17 15.99L12 18.72L7 15.99V12.27L12 15L17 12.27V15.99Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_718">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Проведення семінарів щодо дій працівників при перевірці Держпраці') }}
                          </span>
                        </div>
                    </div>
                    <div @click="scrollToId('#contacts')" class="button">
                        {{ $gettext('Отримати консультацію') }}
                    </div>
                </div>
            </div>
            <div id="financialServices" class="services--item">
                <services-item3/>
                <div class="text">
                    <div class="title">
                        {{ $gettext('Фінансові послуги') }}
                    </div>
                    <div class="desc">
                        {{ $gettext('Готові банківські та фінансові рішення') }}
                    </div>
                    <div class="list">
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1038)">
                                        <path d="M6.5 10H4.5V17H6.5V10ZM12.5 10H10.5V17H12.5V10ZM21 19H2V21H21V19ZM18.5 10H16.5V17H18.5V10ZM11.5 3.26L16.71 6H6.29L11.5 3.26ZM11.5 1L2 6V8H21V6L11.5 1Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1038">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Відкриття рахунків в Українських та європейських банках') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1043)">
                                        <path d="M12.89 11.1C11.11 10.51 10.25 10.14 10.25 9.2C10.25 8.18 11.36 7.81 12.06 7.81C13.37 7.81 13.85 8.8 13.96 9.15L15.54 8.48C15.39 8.03 14.72 6.56 13 6.24V5H11V6.26C8.52 6.82 8.51 9.12 8.51 9.22C8.51 11.49 10.76 12.13 11.86 12.53C13.44 13.09 14.14 13.6 14.14 14.56C14.14 15.69 13.09 16.17 12.16 16.17C10.34 16.17 9.82 14.3 9.76 14.08L8.1 14.75C8.73 16.94 10.38 17.53 11 17.71V19H13V17.76C13.4 17.67 15.9 17.17 15.9 14.54C15.9 13.15 15.29 11.93 12.89 11.1ZM3 21H1V15H7V17H4.52C6.13 19.41 8.88 21 12 21C16.97 21 21 16.97 21 12H23C23 18.08 18.08 23 12 23C8.28 23 4.99 21.15 3 18.33V21ZM1 12C1 5.92 5.92 1 12 1C15.72 1 19.01 2.85 21 5.67V3H23V9H17V7H19.48C17.87 4.59 15.12 3 12 3C7.03 3 3 7.03 3 12H1Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1043">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{
                                $gettext('Документальне оформлення отримання та відправки валюти. Робота з банками')
                                }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1050)">
                                        <path d="M15 10C15 9.45 15.45 9 16 9C16.55 9 17 9.45 17 10C17 10.55 16.55 11 16 11C15.45 11 15 10.55 15 10ZM8 9H13V7H8V9ZM22 7.5V14.47L19.18 15.41L17.5 21H12V19H10V21H4.5C4.5 21 2 12.54 2 9.5C2 6.46 4.46 4 7.5 4H12.5C13.41 2.79 14.86 2 16.5 2C17.33 2 18 2.67 18 3.5C18 3.71 17.96 3.9 17.88 4.08C17.74 4.42 17.62 4.81 17.56 5.23L19.83 7.5H22ZM20 9.5H19L15.5 6C15.5 5.35 15.59 4.71 15.76 4.09C14.79 4.34 14 5.06 13.67 6H7.5C5.57 6 4 7.57 4 9.5C4 11.38 5.22 16.15 6.01 19H8V17H14V19H16.01L17.56 13.85L20 13.03V9.5Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1050">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{ $gettext('Робота з депозитними програмами') }}
                          </span>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1056)">
                                        <path d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM17 14H3V6H17V14ZM10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7ZM23 7V18C23 19.1 22.1 20 21 20H4V18H21V7H23Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1056">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span>
                                {{
                                $gettext('Готові рішення для здійснення виплат працівникам на випробувальному терміні')
                                }}
                          </span>
                        </div>
                    </div>
                    <div @click="scrollToId('#contacts')" class="button">
                        {{ $gettext('Отримати консультацію') }}
                    </div>
                </div>
            </div>
            <div id="legalServices" class="services--item services--item-reversed">
                <services-item4/>
                <div class="text">
                    <div class="title">
                        {{ $gettext('Юридичні послуги') }}
                    </div>
                    <div class="desc">
                        {{ $gettext('Юридичний супровід від А до Я') }}
                    </div>
                    <div class="list">
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1070)">
                                        <path d="M18 12H14V13.5H18V12Z" fill="#FF6B2C"/>
                                        <path d="M18 15H14V16.5H18V15Z" fill="#FF6B2C"/>
                                        <path d="M20 7H15V4C15 2.9 14.1 2 13 2H11C9.9 2 9 2.9 9 4V7H4C2.9 7 2 7.9 2 9V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V9C22 7.9 21.1 7 20 7ZM11 7V4H13V7V9H11V7ZM20 20H4V9H9C9 10.1 9.9 11 11 11H13C14.1 11 15 10.1 15 9H20V20Z"
                                              fill="#FF6B2C"/>
                                        <path d="M9 15C9.82843 15 10.5 14.3284 10.5 13.5C10.5 12.6716 9.82843 12 9 12C8.17157 12 7.5 12.6716 7.5 13.5C7.5 14.3284 8.17157 15 9 15Z"
                                              fill="#FF6B2C"/>
                                        <path d="M11.08 16.18C10.44 15.9 9.74 15.75 9 15.75C8.26 15.75 7.56 15.9 6.92 16.18C6.36 16.42 6 16.96 6 17.57V18H12V17.57C12 16.96 11.64 16.42 11.08 16.18Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1070">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div class="list--item_desc">
                                <span class="list--item_desc-title">
                                    {{ $gettext('Оформлення віз та посвіток на проживання') }}
                                </span>
                                <div class="list--item_desc-info">
                                    {{
                                    $gettext('Швидко, якісно і не дорого допоможемо з отриманням всіх прав на проживання на території України та отримання статуса податкового резидента')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1084)">
                                        <path d="M20 7H16V5L14 3H10L8 5V7H4C2.9 7 2 7.9 2 9V14C2 14.75 2.4 15.38 3 15.73V19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V15.72C21.59 15.37 22 14.73 22 14V9C22 7.9 21.1 7 20 7ZM10 5H14V7H10V5ZM4 9H20V14H15V11H9V14H4V9ZM13 15H11V13H13V15ZM19 19H5V16H9V17H15V16H19V19Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1084">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div class="list--item_desc">
                                <span class="list--item_desc-title">
                                    {{ $gettext('Реєстрація ФОП, ТОВ, ПП') }}
                                </span>
                                <div class="list--item_desc-info">
                                    {{
                                    $gettext('Досвідчені юристи проведуть реєстрацію "під ключ" без лишніх клопотів та спілкування з реєстраційними органами')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1091)">
                                        <path d="M3 19H20V21H3C1.9 21 1 20.1 1 19V6H3V19ZM23 6V15C23 16.1 22.1 17 21 17H7C5.9 17 5 16.1 5 15L5.01 4C5.01 2.9 5.9 2 7 2H12L14 4H21C22.1 4 23 4.9 23 6ZM7 15H21V6H13.17L11.17 4H7V15Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1091">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div class="list--item_desc">
                                <span class="list--item_desc-title">
                                    {{ $gettext('Внесення змін в реєстраційні данні') }}
                                </span>
                                <div class="list--item_desc-info">
                                    {{
                                    $gettext('Ви чи ваша фірма змінила адресу, назву, склад правління? Це не проблема, ми приведемо ваші реєстраційні данні у відповідність')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="list--item">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_0_1100)">
                                        <path d="M23 11.99L20.56 9.2L20.9 5.51L17.29 4.69L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 11.99L3.44 14.78L3.1 18.48L6.71 19.3L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 11.99ZM19.05 13.47L18.49 14.12L18.57 14.97L18.75 16.92L16.85 17.35L16.01 17.54L15.57 18.28L14.58 19.96L12.8 19.19L12 18.85L11.21 19.19L9.43 19.96L8.44 18.29L8 17.55L7.16 17.36L5.26 16.93L5.44 14.97L5.52 14.12L4.96 13.47L3.67 12L4.96 10.52L5.52 9.87L5.43 9.01L5.25 7.07L7.15 6.64L7.99 6.45L8.43 5.71L9.42 4.03L11.2 4.8L12 5.14L12.79 4.8L14.57 4.03L15.56 5.71L16 6.45L16.84 6.64L18.74 7.07L18.56 9.02L18.48 9.87L19.04 10.52L20.33 11.99L19.05 13.47Z"
                                              fill="#FF6B2C"/>
                                        <path d="M10.09 13.75L7.77 11.42L6.29 12.91L10.09 16.72L17.43 9.36L15.95 7.87L10.09 13.75Z"
                                              fill="#FF6B2C"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1100">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div class="list--item_desc">
                                <span class="list--item_desc-title">
                                    {{ $gettext('Реєстрація авторських прав, торгових марок') }}
                                </span>
                                <div class="list--item_desc-info">
                                    {{
                                    $gettext('Організація реєстрації авторських прав і торгових марок в Україні та за кордоном')
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="scrollToId('#contacts')" class="button">
                        {{ $gettext('Отримати консультацію') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.services {
  padding: 64px 0;
  overflow: hidden;

  @media (max-width: 1079px) {
    padding: 30px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &--item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 48px;
    padding: 0 40px;

    @media (max-width: 1079px) {
      gap: 32px;
    }

    &-reversed {
      flex-direction: row-reverse;
    }

    .text {
      max-width: 560px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: -0.8px;
        margin-bottom: 12px;
      }

      .desc {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.4px;
      }

      .list {
        padding: 28px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 1079px) {
          gap: 12px;
        }

        &--item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 12px;

          .icon {
            @media (max-width: 1079px) {
              display: grid;
              place-items: center;
              svg {
                width: 20px;
                height: 20px;
              }
            }
          }

          span {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;

            @media (max-width: 1079px) {
              font-size: 14px;
              line-height: 20px;
            }
          }

          &_desc {
            display: flex;
            flex-direction: column;

            &-info {
              color: #8281B1;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.32px;
            }

            @media (max-width: 1079px) {
              &-title {
                text-align: start;
                font-size: 14px;
                line-height: 20px;
              }

              &-info {
                text-align: start;
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
      }

      .button {
        border-radius: 8px;
        background: #FF7438;
        padding: 16px 32px;
        box-sizing: border-box;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), var(--orange, #FF6B2C);
        }
      }

      @media (max-width: 1079px) {
        align-items: center;
        padding: 0 4px;
        box-sizing: border-box;

        .title {
          text-align: center;
          font-size: 32px;
          line-height: 36px;
        }

        .desc {
          font-size: 20px;
          line-height: 24px;
          text-align: center;
        }
      }
    }

    @media (max-width: 1079px) {
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 24px;

      svg {
        max-width: 319px;
        max-height: 215px;
      }
    }
  }
}
</style>
