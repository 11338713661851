import Cookies from "js-cookie";
export const DEFAULT_LANG = 'uk';
export const DEFAULT_LANG_NAME = 'Українська';
export const CURRENT_LANG = Cookies.get('lang') ?? 'uk';

export const LANG_LIST = [
    {
        code: 'uk',
        name: 'Українська'
    },
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'es',
        name: 'Español'
    }
];

export function getBaseTemplateLang() {
    let base;
    let currentLang = getLangFromPath();

    if (DEFAULT_LANG === currentLang) {
        base = '/';
    } else {
        base = `/:lang/`
    }

    return base;
}

/**
 * @param langId
 */
export function setLangCookie(langId) {
    if (langId !== getLangCookie()) {
        Cookies.set('lang', langId, {expires: 90});
    }
}

/**
 * @returns {?|string}
 */
export function getLangCookie() {
    return CURRENT_LANG;
}

/**
 * @returns {string}
 */
export function getLangFromPath() {
    const path = location.pathname.split('/');
    const lang = LANG_LIST.find((item) => item.code === path[1]);

    return lang?.code ?? DEFAULT_LANG;
}


