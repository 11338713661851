import {gsap} from "gsap";
import ScrollTo from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTo);


export default {
    methods: {
        scrollToId(id) {
            gsap.to(window, {duration: 1.5, scrollTo: {y: id, offsetY: 100}});
        },
        scrollToIdMobile(id) {
            this.menuHandler()
            gsap.to(window, {duration: 1.5, scrollTo: {y: id, offsetY: 100}});
        }
    }
}
