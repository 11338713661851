<script>

import {ref} from "vue";
import {Vue3Lottie} from "vue3-lottie";
import phoneJSON from "../../assets/images/lottie/phone.json";
import presentationJSON from "../../assets/images/lottie/presentation.json";
import opinionJSON from "../../assets/images/lottie/opinion.json";
import scrollToMixin from "../../mixins/scroll-to.mixin";

export default {
    name: "HowWeWorks",
    components: {Vue3Lottie},
    mixins: [
      scrollToMixin
    ],
    setup() {
        const phoneAnimate = ref(null);
        const presentationAnimate = ref(null);
        const opinionAnimate = ref(null);
        const play = (ref) => {
            ref.play()
        }

        const stop = (ref) => {
            ref.stop()
        }


        return {
            play,
            stop,
            phoneAnimate,
            presentationAnimate,
            opinionAnimate,
        }
    },
    data() {
        return {
            phoneLottie: phoneJSON,
            presentationLottie: presentationJSON,
            opinionLottie: opinionJSON,
        }
    },
}

</script>

<template>
    <div class="how-we-works">
        <div class="container">
            <div class="how-we-works_title">
                {{ $gettext('Як ми працюємо') }}
            </div>
            <div class="how-we-works_block">
                <div @click="scrollToId('#contacts')" class="how-we-works_block--item" @mouseover="play(phoneAnimate)" @mouseleave="stop(phoneAnimate)">
                    <div class="text">
                        <div class="text-num">
                            1
                        </div>
                        <div class="text-title">
                            {{ $gettext('Звяжіться з нами зручним способом') }}
                        </div>
                        <div class="text-desc">
                            {{
                            $gettext('Зателефонуйте нам, напишіть листа чи залиште запит на сайті, скориставшись формою нижче')
                            }}
                        </div>
                    </div>
                    <Vue3Lottie ref="phoneAnimate" :animation-data="phoneLottie" :width="82" :height="82"
                                :autoPlay="false"/>
                </div>
                <div @click="scrollToId('#contacts')" class="how-we-works_block--item" @mouseover="play(presentationAnimate)"
                     @mouseleave="stop(presentationAnimate)">
                    <div class="text">
                        <div class="text-num">
                            2
                        </div>
                        <div class="text-title">
                            {{ $gettext('Отримайте індивідуальне рішення') }}
                        </div>
                        <div class="text-desc">
                            {{
                            $gettext('Для кожної компанії, для кожної окремої ситуації ми розробляєм індивідуальне рішення')
                            }}
                        </div>
                    </div>
                    <Vue3Lottie ref="presentationAnimate" :animation-data="presentationLottie" :width="82" :height="82"
                                :autoPlay="false"/>
                </div>
                <div @click="scrollToId('#contacts')" class="how-we-works_block--item" @mouseover="play(opinionAnimate)"
                     @mouseleave="stop(opinionAnimate)">
                    <div class="text">
                        <div class="text-num">
                            3
                        </div>
                        <div class="text-title">
                            {{ $gettext('Отримайте парсонального консультанта') }}
                        </div>
                        <div class="text-desc">
                            {{ $gettext('який допоможе втілити у життя будь-які ваші бізнес задумки') }}
                        </div>
                    </div>
                    <Vue3Lottie ref="opinionAnimate" :animation-data="opinionLottie" :width="82" :height="82"
                                :autoPlay="false"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.how-we-works {
  margin-bottom: 56px;
  @media (max-width: 1079px) {
    margin-bottom: 32px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: -0.8px;
    margin-bottom: 32px;

    @media (max-width: 1079px) {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 16px;
    }
  }

  &_block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;

    @media (max-width: 1079px) {
      flex-direction: column;
      gap: 8px;
    }

    &--item {
      max-width: 392px;
      border-radius: 24px;
      background: #FFF;
      box-shadow: 0 12px 16px 0 rgba(39, 36, 171, 0.08);
      padding: 28px 24px 0 40px;
      box-sizing: border-box;
      height: 268px;
      display: flex;
      flex-direction: row;
      gap: 24px;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0px 12px 24px 0px rgba(39, 36, 171, 0.12);
      }

      @media (max-width: 1079px) {
        padding: 20px 20px 22px 24px;
        height: auto;
        gap: 12px;

        svg {
          width: 56px;
          height: 56px;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 232px;
        width: 100%;
        gap: 12px;

        &-num {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #FF7438;
          display: grid;
          place-items: center;
          color: #FFF;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.4px;
        }

        &-title {
          color: #000;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.4px;
        }

        &-desc {
          color: #8281B1;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.28px;
        }

        @media (max-width: 1079px) {
          max-width: 216px;

          &-desc {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

</style>
