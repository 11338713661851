<script>
export default {
    name: "HeroBgMob"
}

</script>

<template>
    <svg width="360" height="551" viewBox="0 0 360 551" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="180" cy="151" rx="800" ry="400" fill="#015AB4"/>
    </svg>
</template>

<style scoped>

</style>
