<script setup>

</script>

<template>
    <svg width="136" height="40" viewBox="0 0 136 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50 28.564C48.7867 28.564 47.7413 28.4427 46.864 28.2C46.0053 27.9387 45.296 27.5933 44.736 27.164C44.176 26.716 43.756 26.2027 43.476 25.624C43.196 25.0267 43.0373 24.392 43 23.72H47.312C47.424 24.1307 47.6667 24.4947 48.04 24.812C48.4133 25.1107 49.0667 25.26 50 25.26C50.952 25.26 51.6333 25.1293 52.044 24.868C52.4547 24.6067 52.66 24.2613 52.66 23.832C52.66 23.608 52.5667 23.412 52.38 23.244C52.212 23.0573 51.8853 22.8893 51.4 22.74C50.9333 22.5907 50.252 22.4413 49.356 22.292C48.5347 22.1613 47.76 21.9933 47.032 21.788C46.3227 21.5827 45.6973 21.312 45.156 20.976C44.6147 20.6213 44.1853 20.1733 43.868 19.632C43.5693 19.0907 43.42 18.4093 43.42 17.588C43.42 16.7107 43.6813 15.9267 44.204 15.236C44.7267 14.5453 45.4827 14.004 46.472 13.612C47.4613 13.2013 48.6373 12.996 50 12.996C51.5493 12.996 52.772 13.2387 53.668 13.724C54.5827 14.1907 55.2547 14.7973 55.684 15.544C56.1133 16.272 56.3653 17.0373 56.44 17.84H52.1C51.988 17.3733 51.7453 17 51.372 16.72C51.0173 16.44 50.56 16.3 50 16.3C49.1413 16.3 48.544 16.4307 48.208 16.692C47.8907 16.9533 47.732 17.2707 47.732 17.644C47.732 18.0173 47.9933 18.316 48.516 18.54C49.0387 18.7453 49.8693 18.9227 51.008 19.072C51.8293 19.184 52.5947 19.3427 53.304 19.548C54.032 19.7533 54.6667 20.0427 55.208 20.416C55.768 20.7707 56.2067 21.228 56.524 21.788C56.8413 22.3293 57 22.992 57 23.776C57 24.7093 56.7293 25.54 56.188 26.268C55.6653 26.996 54.8813 27.5653 53.836 27.976C52.8093 28.368 51.5307 28.564 50 28.564Z" fill="black"/>
        <path d="M64.968 28.564C63.512 28.564 62.2053 28.2933 61.048 27.752C59.8906 27.192 58.9666 26.3427 58.276 25.204C57.604 24.0653 57.268 22.6373 57.268 20.92V20.36C57.268 18.7173 57.5946 17.3547 58.248 16.272C58.9013 15.1893 59.788 14.3773 60.908 13.836C62.0466 13.276 63.3066 12.996 64.688 12.996C67.1333 12.996 68.9813 13.6307 70.232 14.9C71.4826 16.1507 72.108 17.9707 72.108 20.36V21.928H61.692C61.7293 22.656 61.8973 23.2627 62.196 23.748C62.5133 24.2333 62.9146 24.588 63.4 24.812C63.8853 25.0173 64.408 25.12 64.968 25.12C65.7893 25.12 66.4333 24.9987 66.9 24.756C67.3853 24.4947 67.7026 24.1493 67.852 23.72H71.828C71.5293 25.344 70.792 26.5573 69.616 27.36C68.44 28.1627 66.8906 28.564 64.968 28.564ZM64.828 16.44C64.3053 16.44 63.8106 16.524 63.344 16.692C62.8773 16.86 62.4946 17.14 62.196 17.532C61.916 17.9053 61.7573 18.4187 61.72 19.072H67.88C67.88 18.3813 67.74 17.8493 67.46 17.476C67.18 17.084 66.8066 16.8133 66.34 16.664C65.892 16.5147 65.388 16.44 64.828 16.44Z" fill="black"/>
        <path d="M72.9436 28.2V13.36H77.0036V15.488C77.3396 14.8347 77.8623 14.256 78.5716 13.752C79.2809 13.248 80.1583 12.996 81.2036 12.996C82.3796 12.996 83.2849 13.22 83.9196 13.668C84.5729 14.116 85.0489 14.7227 85.3476 15.488C85.7209 14.7787 86.2996 14.1907 87.0836 13.724C87.8676 13.2387 88.7916 12.996 89.8556 12.996C91.4609 12.996 92.6836 13.4533 93.5236 14.368C94.3636 15.264 94.7836 16.608 94.7836 18.4V28.2H90.4716V19.464C90.4716 18.4187 90.3036 17.6813 89.9676 17.252C89.6503 16.804 89.1276 16.58 88.3996 16.58C87.9703 16.58 87.5689 16.7013 87.1956 16.944C86.8409 17.168 86.5516 17.4947 86.3276 17.924C86.1223 18.3533 86.0196 18.8667 86.0196 19.464V28.2H81.7076V19.464C81.7076 18.4187 81.5396 17.6813 81.2036 17.252C80.8863 16.804 80.3636 16.58 79.6356 16.58C79.1876 16.58 78.7863 16.7013 78.4316 16.944C78.0769 17.168 77.7876 17.4947 77.5636 17.924C77.3583 18.3533 77.2556 18.8667 77.2556 19.464V28.2H72.9436Z" fill="black"/>
        <path d="M102.344 28.368C101.112 28.368 100.104 28.2093 99.3196 27.892C98.5356 27.556 97.9569 27.0147 97.5836 26.268C97.2103 25.5027 97.0236 24.4667 97.0236 23.16V16.888H95.2036V13.36H97.0236V10H101.336V13.36H104.724V16.888H101.336V22.46C101.336 23.3 101.466 23.8973 101.728 24.252C102.008 24.6067 102.54 24.784 103.324 24.784C103.884 24.784 104.444 24.756 105.004 24.7V28.2C104.649 28.256 104.276 28.2933 103.884 28.312C103.492 28.3493 102.978 28.368 102.344 28.368Z" fill="black"/>
        <path d="M110.475 28.564C109.411 28.564 108.496 28.3587 107.731 27.948C106.984 27.5373 106.415 26.9773 106.023 26.268C105.631 25.54 105.435 24.7373 105.435 23.86C105.435 22.3853 105.883 21.256 106.779 20.472C107.693 19.688 109.065 19.296 110.895 19.296H115.095V18.68C115.095 17.784 114.88 17.168 114.451 16.832C114.04 16.496 113.461 16.328 112.715 16.328C112.005 16.328 111.483 16.44 111.147 16.664C110.811 16.8693 110.624 17.2147 110.587 17.7H106.275C106.331 16.804 106.611 16.0013 107.115 15.292C107.637 14.5827 108.375 14.0227 109.327 13.612C110.297 13.2013 111.473 12.996 112.855 12.996C114.161 12.996 115.309 13.192 116.299 13.584C117.288 13.976 118.053 14.6107 118.595 15.488C119.155 16.3467 119.435 17.504 119.435 18.96V24.42C119.435 25.204 119.453 25.8947 119.491 26.492C119.528 27.0707 119.584 27.64 119.659 28.2H115.543C115.524 27.8827 115.496 27.6027 115.459 27.36C115.44 27.1173 115.431 26.8187 115.431 26.464C114.983 27.0613 114.357 27.5653 113.555 27.976C112.771 28.368 111.744 28.564 110.475 28.564ZM111.875 25.512C112.491 25.512 113.041 25.4 113.527 25.176C114.012 24.952 114.395 24.616 114.675 24.168C114.955 23.7013 115.095 23.1787 115.095 22.6V21.984H111.735C111.1 21.984 110.624 22.152 110.307 22.488C110.008 22.824 109.859 23.2067 109.859 23.636C109.859 24.196 110.008 24.6533 110.307 25.008C110.624 25.344 111.147 25.512 111.875 25.512Z" fill="black"/>
        <path d="M119.577 28.2L124.309 20.472L119.857 13.36H124.617L127.529 17.868L130.329 13.36H134.977L130.553 20.332L135.257 28.2H130.469L127.361 22.908L124.253 28.2H119.577Z" fill="black"/>
        <mask id="mask0_2_2" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
            <rect width="40" height="40" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2_2)">
            <path d="M33.2134 18.1063C33.2134 18.2803 33.2139 18.4501 33.2148 18.616C33.1513 18.0796 33.0312 17.6615 32.8168 17.2967C32.5838 16.9001 32.2393 16.5665 31.7353 16.2116L28.4305 14.3036C27.8651 13.9907 27.2353 13.638 26.5329 13.2326C25.8307 12.8272 25.2105 12.4581 24.657 12.125L21.3511 10.2163C20.7768 9.95026 20.3057 9.81903 19.8333 9.82266C19.3528 9.82632 18.8708 9.96968 18.277 10.2525L15.3656 11.9333C14.7344 12.3149 14.0115 12.7477 13.1717 13.2327C12.3319 13.7176 11.5956 14.1269 10.9498 14.4828L8.03757 16.1642C7.48184 16.5467 7.11216 16.9009 6.86884 17.3299C6.66643 17.6869 6.55154 18.0955 6.49003 18.6156C6.49076 18.4498 6.49129 18.2801 6.49114 18.1063C6.49118 17.4162 6.48429 16.7907 6.47506 16.2215L6.47511 12.0566C6.53239 11.4697 6.64888 11.0203 6.86897 10.6324C7.11222 10.2036 7.4819 9.84941 8.0377 9.46676L10.95 7.78545C11.5959 7.42942 12.3323 7.02012 13.172 6.53522C14.0119 6.05039 14.7347 5.61737 15.3659 5.23594L18.2773 3.55509C18.871 3.27241 19.3531 3.12893 19.8335 3.12527C20.3059 3.12164 20.7771 3.25287 21.3514 3.51893L24.6573 5.42758C25.2108 5.76069 25.831 6.12979 26.5332 6.53521C27.2354 6.94063 27.8654 7.29332 28.4309 7.60627L31.7356 9.51427C32.2397 9.86913 32.5842 10.2028 32.8171 10.5993C33.0493 10.9945 33.1709 11.4523 33.2299 12.0551L33.2298 16.2347C33.2206 16.8005 33.2139 17.4215 33.2139 18.1064" fill="#003BAF"/>
            <path d="M9.86623 15.1085L8.03756 16.1642C7.57832 16.4803 7.24607 16.7772 7.00726 17.1127C6.82456 17.0677 6.64922 17.0008 6.48457 16.9144C6.48191 16.6746 6.47864 16.4437 6.47505 16.2214L6.47498 12.8128L6.48992 12.8049C7.29693 13.9586 8.49761 14.7778 9.86618 15.1083" fill="#002D85"/>
            <path d="M32.8561 17.3659C32.8435 17.3427 32.8305 17.3196 32.8171 17.2968C32.5841 16.9003 32.2396 16.5665 31.7356 16.2118L29.3451 14.8317C30.9018 14.8833 32.3712 14.1137 33.2151 12.8048L33.2298 12.8139L33.2298 16.2347C33.225 16.5356 33.2207 16.8519 33.2179 17.1852C33.1023 17.2549 32.9814 17.3153 32.8563 17.3659" fill="#002D85"/>
            <path d="M6.83349 13.249L6.83342 12.0743C6.87709 11.6376 6.96176 11.1952 7.18063 10.8095C7.42679 10.3753 7.82398 10.0502 8.22915 9.77036L11.1257 8.09803C11.8712 7.68726 12.6137 7.27164 13.3508 6.84604C14.0881 6.42038 14.8192 5.98513 15.5479 5.54494L18.4437 3.87302C18.8781 3.66706 19.349 3.488 19.8357 3.48413C20.3088 3.48054 20.7612 3.64243 21.1857 3.83787L24.4745 5.73668C25.0977 6.11186 25.7234 6.48226 26.3533 6.84598C26.9833 7.2097 27.6174 7.56639 28.2538 7.91863L31.5416 9.81686C31.9138 10.0799 32.2738 10.3837 32.5074 10.7811C32.7381 11.1737 32.8258 11.6248 32.8706 12.0728L32.8706 13.2691C32.7121 13.4561 32.5393 13.6282 32.3545 13.7847C32.1307 13.4941 31.84 13.257 31.5416 13.046L28.2538 11.1478C27.6174 10.7955 26.9835 10.4389 26.3533 10.0751C25.7232 9.71134 25.0978 9.34088 24.4747 8.9659L21.1857 7.06702C20.7613 6.87145 20.3089 6.70956 19.8358 6.71315C19.3489 6.71695 18.8782 6.89608 18.4437 7.10197L15.5478 8.77389C14.8192 9.21408 14.088 9.64933 13.3508 10.0749C12.6136 10.5006 11.8712 10.9161 11.1258 11.3269L8.2291 12.9993C7.90811 13.2211 7.59217 13.4712 7.35214 13.7815C7.16846 13.6158 6.99529 13.4379 6.83351 13.2487" fill="url(#paint0_linear_2_2)"/>
            <path d="M33.2133 13.3144C33.2133 13.1404 33.2139 12.9707 33.2145 12.8047C33.151 13.341 33.0309 13.7592 32.8166 14.124C32.5836 14.5204 32.2391 14.8542 31.7351 15.209L28.4305 17.117C27.8651 17.4299 27.2352 17.7825 26.5329 18.1881C25.8307 18.5935 25.2105 18.9626 24.6569 19.2957L21.351 21.2043C20.7765 21.4704 20.3055 21.6017 19.8331 21.598C19.3526 21.5943 18.8706 21.4509 18.2768 21.1682L15.3655 19.4874C14.7342 19.1059 14.0115 18.6729 13.1716 18.188C12.3317 17.7031 11.5955 17.2938 10.9495 16.9378L8.03722 15.2564C7.48148 14.8739 7.11183 14.5197 6.86856 14.0908C6.66618 13.7339 6.55137 13.3252 6.4897 12.8051C6.49045 12.9709 6.49097 13.1405 6.49092 13.3144C6.49095 14.0044 6.48409 14.6299 6.47477 15.1992L6.47478 16.2214L6.47485 19.3641C6.53205 19.9511 6.64871 20.4003 6.86875 20.7883C7.11195 21.2173 7.48179 21.5714 8.03741 21.9539L10.9497 23.6353C11.5957 23.9912 12.3319 24.4006 13.1717 24.8854C14.0116 25.3703 14.7343 25.8033 15.3656 26.1848L18.2768 27.8656C18.8707 28.1483 19.3527 28.2917 19.8331 28.2953C20.3055 28.2989 20.7766 28.1678 21.351 27.9017L24.6569 25.9931C25.2105 25.66 25.8307 25.2909 26.533 24.8855C27.2354 24.48 27.8651 24.1273 28.4305 23.8144L31.7353 21.9065C32.2393 21.5516 32.5838 21.2179 32.8168 20.8215C33.0491 20.4262 33.1706 19.9686 33.2296 19.3658L33.2295 16.2346L33.2295 15.1861C33.2202 14.6204 33.2135 13.9992 33.2135 13.3144" fill="#FF6B2C"/>
            <path d="M6.83436 16.1173L6.83437 19.3465C6.87795 19.7832 6.96265 20.2256 7.18153 20.6112C7.42773 21.0455 7.82475 21.3705 8.23 21.6503L11.1266 23.3226C11.8721 23.7335 12.6145 24.1489 13.3517 24.5745C14.0889 25.0001 14.8202 25.4354 15.5487 25.8757L18.4445 27.5476C18.8789 27.7536 19.3498 27.9328 19.8365 27.9365C20.3096 27.9401 20.762 27.7783 21.1863 27.5827L24.4754 25.6839C25.0985 25.3089 25.7242 24.9383 26.3542 24.5746C26.9842 24.2109 27.6182 23.8541 28.2547 23.5019L31.5425 21.6037C31.9146 21.3406 32.2748 21.0368 32.5082 20.6394C32.7389 20.2468 32.8266 19.7957 32.8714 19.3477L32.8714 16.1186C32.8266 16.5666 32.7388 17.0178 32.5082 17.4103C32.2747 17.8075 31.9146 18.1115 31.5425 18.3745L28.2547 20.2727C27.6182 20.625 26.9844 20.9817 26.3543 21.3455C25.7244 21.7091 25.0987 22.0797 24.4755 22.4547L21.1867 24.3536C20.7623 24.5492 20.3097 24.711 19.8368 24.7074C19.35 24.7035 18.8791 24.5244 18.4447 24.3184L15.5489 22.6465C14.8203 22.2063 14.0892 21.771 13.3519 21.3453C12.6146 20.9196 11.8724 20.5042 11.1268 20.0934L8.23006 18.421C7.82489 18.1411 7.42786 17.8161 7.1816 17.382C6.96279 16.9962 6.87809 16.5538 6.83443 16.1172" fill="url(#paint1_linear_2_2)"/>
            <path d="M20.4221 30.387C20.2211 30.4311 20.0271 30.4523 19.8329 30.4509C19.6516 30.4496 19.4702 30.4282 19.2826 30.3871C18.6122 30.3042 17.9836 30.0817 17.3625 29.786L17.2863 29.7497L14.2836 28.0161L14.2654 28.0051C13.55 27.5728 12.8321 27.1453 12.1081 26.7273C11.3841 26.3093 10.6551 25.9013 9.92304 25.498L9.90439 25.4878L6.9007 23.7536L6.83118 23.7057C6.71225 23.6239 6.59603 23.5402 6.48295 23.454C6.48051 23.6601 6.47762 23.8594 6.47456 24.0521L6.47457 25.0743L6.47464 28.217C6.53185 28.804 6.6485 29.2532 6.86854 29.6412C7.11174 30.0702 7.48146 30.4243 8.0372 30.8068L10.9495 32.4882C11.5955 32.8442 12.3317 33.2535 13.1715 33.7383C14.0112 34.2232 14.7341 34.6562 15.3653 35.0377L18.2766 36.7186C18.8704 37.0014 19.3525 37.1446 19.833 37.1483C20.3054 37.1519 20.7765 37.0208 21.351 36.7547L24.6568 34.8461C25.2105 34.513 25.8306 34.1439 26.5329 33.7385C27.2353 33.333 27.8651 32.9802 28.4304 32.6674L31.7351 30.7594C32.2392 30.4046 32.5836 30.0708 32.8166 29.6744C33.0488 29.2791 33.1704 28.8215 33.2294 28.2187L33.2293 25.0875L33.2293 24.039C33.2262 23.8503 33.2234 23.6554 33.2211 23.454C33.1355 23.5192 33.0482 23.583 32.9592 23.6455L32.8811 23.7006L29.4769 25.666L29.46 25.6753C28.8357 26.0208 28.2139 26.3707 27.596 26.7273C26.9782 27.084 26.3645 27.4475 25.7534 27.8154L25.7367 27.8254L22.3313 29.7914L22.2447 29.8315C21.6529 30.1056 21.0552 30.3086 20.4221 30.387Z" fill="#FF6B2C"/>
            <path d="M6.8334 24.9703L6.83341 28.1994C6.87699 28.6362 6.96177 29.0784 7.18058 29.4642C7.42677 29.8984 7.82399 30.2234 8.22909 30.5034L11.1257 32.1758C11.8713 32.5865 12.6136 33.0021 13.3508 33.4277C14.088 33.8533 14.8193 34.2885 15.5478 34.7289L18.4436 36.4007C18.878 36.6068 19.3489 36.7858 19.8356 36.7896C20.3086 36.7934 20.7611 36.6314 21.1854 36.4359L24.4745 34.537C25.0976 34.162 25.7233 33.7915 26.3532 33.4279C26.9832 33.0641 27.6172 32.7074 28.2538 32.3551L31.5416 30.4569C31.9136 30.1939 32.2738 29.8901 32.5072 29.4927C32.7379 29.1001 32.8257 28.649 32.8705 28.201L32.8704 24.9719C32.8257 25.4198 32.7379 25.8709 32.5072 26.2635C32.2737 26.6609 31.9136 26.9648 31.5416 27.2278L28.2537 29.126C27.6172 29.4782 26.9834 29.8349 26.3534 30.1987C25.7235 30.5623 25.0978 30.9328 24.4746 31.308L21.1857 33.2069C20.7613 33.4024 20.3088 33.5644 19.8358 33.5606C19.349 33.5569 18.8783 33.3778 18.4437 33.1717L15.548 31.4998C14.8194 31.0595 14.0883 30.6243 13.3509 30.1986C12.6136 29.7729 11.8714 29.3574 11.1258 28.9467L8.22908 27.2743C7.82391 26.9944 7.42689 26.6693 7.18069 26.2351C6.96189 25.8493 6.87718 25.407 6.83353 24.9703" fill="url(#paint2_linear_2_2)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_2_2" x1="30.1511" y1="17.9793" x2="9.81858" y2="6.2403" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0056FF"/>
                <stop offset="1" stop-color="#0056FF" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2_2" x1="30.2788" y1="24.501" x2="9.06525" y2="12.2533" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFAB2C"/>
                <stop offset="1" stop-color="#FF6B2C"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2_2" x1="30.2779" y1="33.3542" x2="9.06418" y2="21.1064" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFAB2C"/>
                <stop offset="1" stop-color="#FF6B2C"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<style scoped>

</style>
