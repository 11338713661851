<script>
export default {
    name: "FeaturesItem4"
}

</script>

<template>
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M66.022 63.4219C66.8756 63.4219 67.5689 62.73 67.5689 61.875V1.54688C67.5689 0.691875 66.8756 0 66.022 0H10.267C7.04953 0 4.43109 2.61844 4.43109 5.83594V66.1641C4.43109 69.3816 7.04953 72 10.267 72H66.022C66.8756 72 67.5689 71.3081 67.5689 70.4531C67.5689 69.5981 66.8756 68.9062 66.022 68.9062H63.2798V63.4219H66.022ZM16.103 3.09375H64.4752V60.3281H16.103V3.09375ZM7.52484 5.83594C7.52484 4.32422 8.75531 3.09375 10.267 3.09375H13.0092V60.3281H10.267C9.27703 60.3281 8.34328 60.577 7.52484 61.0144V5.83594ZM60.1861 68.9062H10.267C8.75531 68.9062 7.52484 67.6758 7.52484 66.1641C7.52484 64.6523 8.75531 63.4219 10.267 63.4219H60.1861V68.9062Z" fill="#FF6B2C"/>
        <path d="M55.658 24.6812H57.4439C58.2975 24.6812 58.9908 23.9879 58.9908 23.1343C58.9908 22.2793 58.2975 21.5874 57.4439 21.5874H41.8359V18.8452C41.8359 17.9902 41.1427 17.2983 40.2891 17.2983C39.4341 17.2983 38.7422 17.9902 38.7422 18.8452V21.5874H23.1342C22.2792 21.5874 21.5873 22.2793 21.5873 23.1343C21.5873 23.9879 22.2792 24.6812 23.1342 24.6812H24.9202L21.7505 31.0191C21.6422 31.2343 21.5873 31.4705 21.5873 31.711C21.5873 34.9285 24.2044 37.5469 27.4219 37.5469C30.6408 37.5469 33.2578 34.9285 33.2578 31.711C33.2578 31.4705 33.2016 31.2343 33.0947 31.0191L29.925 24.6812H38.7422V47.319H31.7109C30.8573 47.319 30.1641 48.0122 30.1641 48.8658C30.1641 49.7208 30.8573 50.4127 31.7109 50.4127H48.8658C49.7208 50.4127 50.4127 49.7208 50.4127 48.8658C50.4127 48.0122 49.7208 47.319 48.8658 47.319H41.8359V24.6812H50.6517L47.4834 31.0191C47.3752 31.2343 47.3189 31.4705 47.3189 31.711C47.3189 34.9285 49.9373 37.5469 53.1548 37.5469C56.3723 37.5469 58.9908 34.9285 58.9908 31.711C58.9908 31.4705 58.9345 31.2343 58.8277 31.0191L55.658 24.6812ZM30.1444 32.0372C29.9827 33.3957 28.8239 34.4532 27.4219 34.4532C26.0212 34.4532 24.8611 33.3957 24.6994 32.0372L27.4219 26.5922L30.1444 32.0372ZM53.1548 34.4532C51.7542 34.4532 50.5941 33.3957 50.4323 32.0372L53.1548 26.5922L55.8773 32.0372C55.7156 33.3957 54.5569 34.4532 53.1548 34.4532Z" fill="black"/>
    </svg>
</template>

<style scoped>

</style>
