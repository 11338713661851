<script>
export default {
    name: "HeroBg"
}

</script>

<template>
    <svg width="1440" height="640" viewBox="0 0 1440 640" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="720" cy="240" rx="1600" ry="400" fill="#015AB4"/>
    </svg>
</template>

<style scoped>

</style>
