<script>
import ReviewsItems from '../../reviews/reviews.json'
import LinkedInIcon from "../../assets/images/icons/linkedInIcon.vue";
import FacebookIcon from "../../assets/images/icons/facebookIcon.vue";

export default {
    name: "ReviewsBlock",
    components: {FacebookIcon, LinkedInIcon},
    data() {
        return {
            reviews: ReviewsItems,
            displayedReviews: [],
            showAllItems: false,
            mobileBreakpoint: 1079
        };
    },
    mounted() {
        this.showInitialItems();
        window.addEventListener('resize', this.handleWindowResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {
        showInitialItems() {
            const initialItems = this.isMobile ? 3 : 6;
            this.displayedReviews = this.reviews.reviews.slice(0, initialItems);

            this.resetMaxHeight();
            this.showAllItems = false;
        },
        showMoreItems() {
            if (this.isMobile) {
                this.displayedReviews = this.reviews.reviews;
                this.showAllItems = true;
            } else {
                const additionalItems = 6;
                const currentLength = this.displayedReviews.length;
                const endIndex = currentLength + additionalItems;
                if (endIndex >= this.reviews.reviews.length) {
                    this.displayedReviews = this.reviews.reviews;
                    this.showAllItems = true;
                } else {
                    this.displayedReviews = this.reviews.reviews.slice(0, endIndex);
                }
            }
            this.updateMaxHeight();
        },
        updateMaxHeight() {
            const reviewsBlock = this.$refs.reviewsBlock;
            reviewsBlock.style.maxHeight = this.isMobile ? 'none' : '1300px';
        },
        resetMaxHeight() {
            const reviewsBlock = this.$refs.reviewsBlock;
            reviewsBlock.style.maxHeight = this.isMobile ? '1000px' : '630px';
        },
        handleWindowResize() {
            this.resetMaxHeight();
            this.showInitialItems();
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < this.mobileBreakpoint;
        }
    }
}
</script>

<template>
    <div class="reviews">
        <div class="container">
            <div class="title">
                {{ $gettext('Відгуки наших клієнтів') }}
            </div>
            <div class="reviews_block" ref="reviewsBlock">
                <div v-for="(item, index) in displayedReviews" :key="index" class="reviews_block--item">
                    <div class="author">
                        <img v-if="item.avatar === 1" src="../../assets/images/authors/author1.png" height="64" width="64"/>
                        <img v-if="item.avatar === 2" src="../../assets/images/authors/author2.png" height="64" width="64"/>
                        <img v-if="item.avatar === 3" src="../../assets/images/authors/author3.png" height="64" width="64"/>
                        <img v-if="item.avatar === 4" src="../../assets/images/authors/author4.png" height="64" width="64"/>
                    </div>
                    <div class="social">
                        <linked-in-icon v-if="item.socials === 'Linkedin'"/>
                        <facebook-icon v-if="item.socials === 'Facebook'"/>
                        <span v-if="item.socials === 'Linkedin' || item.socials === 'Facebook'">
                            {{ $gettext('Через') }}
                        </span>
                        <span style="margin-left: 4px">{{ $gettext(item.socials) }}</span>
                    </div>
                    <div class="name">
                        {{ $gettext(item.name) }}
                    </div>
                    <div class="position">
                        {{ $gettext(item.position) }}
                    </div>
                    <div class="message" v-html="$gettext(item.message)"/>
                </div>
            </div>
            <div class="button" @click="showMoreItems" v-if="!showAllItems">
                {{$gettext('Показати ще')}}
            </div>
            <div class="button" @click="showInitialItems" v-if="showAllItems">
                {{$gettext('Показати меньше')}}
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.reviews {
  margin-bottom: 56px;

  @media (max-width: 1079px) {
    overflow: hidden;
    margin-bottom: 40px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 115% */
    letter-spacing: -0.8px;
    margin-bottom: 24px;

    @media (max-width: 1079px) {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 16px;
    }
  }

  &_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 630px;
    gap: 24px;

    @media (max-width: 1079px) {
      max-height: 1300px;
      gap: 6px;
    }

    &--item {
      max-width: 392px;
      font-family: 'Golos Text', sans-serif;
      border-radius: 24px;
      border: 2px solid rgba(1, 90, 180, 0.08);
      padding: 21px 32px 28px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      transition: all .3s ease-in-out;
      cursor: pointer;

      @media (max-width: 1079px) {
        padding: 16px 16px 16px 20px;
      }

      &:hover {
        border: 2px solid rgba(1, 90, 180, 0.20);

          .social {
              background: #015AB4;
              color: white;

              svg {
                  path {
                      fill: white;
                  }
              }
          }
      }

      .author {
        position: absolute;
        top: 24px;
        right: 24px;
      }

      .social {
        border-radius: 11px;
        background: rgba(1, 90, 180, 0.08);
        color: #015AB4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        padding: 0 8px;
        margin-bottom: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: all .3s ease-in-out;
        cursor: pointer;
        transform: translateX(-8px);

        svg {
          margin-right: 8px;

          path {
            transition: all .3s ease-in-out;
          }
        }
      }

      .name {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.36px;
        margin-bottom: 6px;
      }

      .position {
        color: #8281B1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.28px;
        margin-bottom: 16px;
      }

      .message {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.28px;
      }
    }
  }

  .button {
    margin-top: 24px;
    border-radius: 8px;
    background: #FF7438;
    padding: 16px 32px;
    box-sizing: border-box;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), var(--orange, #FF6B2C);
    }

    @media (max-width: 1079px) {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
