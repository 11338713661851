<script>

export default {
    name: "PageNotFoundImg"
}

</script>

<template>
    <svg width="390" height="280" viewBox="0 0 390 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M373.173 279.381C348.572 279.381 15.9768 278.665 15.9768 278.626C15.839 278.304 -26.2444 226.852 26.7475 173.058C53.0053 146.404 2.63462 111.872 26.7475 82.2224C68.0524 31.4341 108.921 60.0375 142.966 45.7532C148.982 43.2312 155.018 39.1741 161.061 32.946C164.462 29.4437 168.189 26.3818 172.18 23.7372C172.793 23.3299 173.41 22.9325 174.035 22.5466C176.423 21.0678 178.902 19.7315 181.454 18.5293C183.168 17.7228 184.913 16.9776 186.693 16.2921C189.515 15.2041 192.415 14.2652 195.377 13.472C199.583 12.3443 203.913 11.5047 208.329 10.9367C210.255 10.6866 212.197 10.4912 214.154 10.3439C252.197 7.49065 295.007 23.5369 317.102 46.9902C321.549 51.713 341.776 76.1483 325.832 109.736C315.765 130.942 357.938 151.195 372.924 175.411C411.025 236.978 373.518 278.578 373.171 279.381H373.173Z" fill="#F1F8FF"/>
        <g opacity="0.8">
            <path d="M287.094 136.238C286.078 139.023 284.833 141.663 283.384 144.137C279.906 150.075 275.268 155.068 269.872 158.928C257.337 167.9 240.721 170.747 225.126 165.091C206.574 158.365 194.658 141.494 193.39 123.038C193.221 120.606 193.237 118.147 193.451 115.679C193.803 111.536 194.706 107.37 196.197 103.278C197.404 99.9676 198.936 96.8676 200.744 94.0045C200.764 93.9714 200.786 93.9399 200.806 93.9068C205.826 85.9979 212.956 79.8924 221.157 76.0655C223.483 74.9792 225.896 74.0767 228.373 73.3696C229.554 73.0318 230.752 72.7387 231.957 72.4936C240.419 70.7565 249.451 71.2616 258.169 74.4228C259.324 74.8417 260.455 75.3004 261.557 75.7972C263.114 76.4944 264.616 77.2677 266.062 78.109C272.153 81.651 277.25 86.4053 281.158 91.9395H281.159C283.595 95.3856 285.565 99.133 287.024 103.079C287.791 105.152 288.417 107.279 288.894 109.446C289.191 110.796 289.43 112.157 289.611 113.535C290.046 116.842 290.135 120.215 289.863 123.606C289.521 127.824 288.613 132.07 287.094 136.241V136.238Z" fill="#D7E5FF"/>
            <path d="M233.601 93.8654C230.744 96.0728 231.501 101.98 226.554 98.6512C221.607 95.321 218.502 93.5111 217.101 96.4173C215.698 99.3235 220.083 100.913 218.929 104.079C217.775 107.246 215.428 107.58 212.954 104.98C210.477 102.38 210.406 99.8782 206.398 99.961C202.389 100.044 200.679 98.3995 200.875 95.3972C200.908 94.869 200.855 94.4119 200.744 94.0045C200.764 93.9714 200.786 93.94 200.806 93.9068C205.826 85.998 212.956 79.8925 221.157 76.0656C223.482 74.9792 225.896 74.0767 228.373 73.3697C229.553 73.0318 230.752 72.7387 231.957 72.4937C233.355 73.06 234.854 74.2092 235.648 76.5143C237.392 81.5865 240.223 80.3926 237.819 84.6418C235.414 88.8893 236.405 89.9325 239.028 90.7125C241.652 91.4924 244.675 92.5887 244.264 94.6586C243.85 96.7286 236.459 91.6564 233.601 93.8654Z" fill="#AEC3FF"/>
            <path d="M287.093 136.238C286.077 139.023 284.832 141.663 283.383 144.137C279.905 150.075 275.267 155.068 269.871 158.928C268.527 158.887 267.303 158.431 266.272 157.512C261.428 153.195 267.848 148.611 271.228 146.379C274.608 144.149 268.333 143.408 265.168 139.062C262.004 134.715 269.413 128.823 264.652 128.505C259.891 128.187 254.101 127.879 253.131 122.792C252.162 117.705 257.17 118.751 256.293 114.466C255.417 110.18 259.559 109.377 263.99 110.601C268.42 111.823 268.967 108.565 274.458 110.044C279.952 111.523 277.365 118.266 283.424 119.952C289.484 121.638 288.723 122.898 288.723 122.898C289.152 123.04 289.532 123.279 289.861 123.601C289.519 127.819 288.611 132.065 287.092 136.236L287.093 136.238Z" fill="#AEC3FF"/>
            <path d="M289.612 113.533C283.843 114.134 284.234 106.951 279.336 107.378C274.398 107.807 276.511 104.305 274.067 100.688C271.62 97.0713 263.378 99.032 265.053 95.3707C266.728 91.7126 271.331 94.5741 273.488 91.9445C275.644 89.3132 280.206 91.8203 280.206 91.8203C280.518 91.8153 280.837 91.8551 281.159 91.9395C281.159 91.9395 281.159 91.9379 281.161 91.9395C283.596 95.3856 285.567 99.1313 287.026 103.079C287.793 105.152 288.419 107.279 288.895 109.446C289.192 110.796 289.431 112.157 289.612 113.535V113.533Z" fill="#AEC3FF"/>
            <path d="M253.057 85.5575C253.057 85.5575 256.973 82.7921 253.291 80.2204C249.608 77.6503 249.163 79.1059 246.113 77.6172C243.062 76.1285 240.922 78.1272 242.328 81.666C243.733 85.2048 243.789 91.0387 246.94 89.0831C250.091 87.1274 250.431 84.9034 253.059 85.5575H253.057Z" fill="#AEC3FF"/>
        </g>
        <path d="M26.9595 278.763H43.8261C44.7292 277.998 43.2002 276.261 41.6032 276.145C40.9093 276.092 40.0311 276.238 39.0284 276.448C38.796 273.373 37.4712 259.549 32.476 260.105C26.8599 260.728 32.476 266.193 34.0631 269.428C35.2401 271.825 36.4802 275.262 37.0463 276.885C36.5897 276.983 36.1183 277.081 35.6352 277.168C34.8914 274.888 32.1207 267.219 28.598 267.666C24.4627 268.19 26.1245 271.265 29.0562 273.09C31.206 274.433 33.8472 276.34 35.1023 277.261C34.0398 277.435 32.9292 277.551 31.8037 277.524C28.9151 277.456 27.504 278.304 26.9578 278.761L26.9595 278.763Z" fill="#A8D29F"/>
        <path d="M295.38 278.763H279.713C278.873 278.053 280.294 276.44 281.779 276.33C282.423 276.282 283.238 276.417 284.169 276.612C284.385 273.755 285.615 260.915 290.255 261.432C295.473 262.01 290.255 267.087 288.781 270.092C287.688 272.318 286.536 275.511 286.008 277.018C286.432 277.109 286.87 277.198 287.318 277.281C288.011 275.163 290.582 268.039 293.854 268.455C297.696 268.942 296.152 271.796 293.429 273.492C291.432 274.739 288.978 276.511 287.813 277.367C288.801 277.528 289.832 277.637 290.876 277.612C293.56 277.549 294.868 278.337 295.376 278.761L295.38 278.763Z" fill="#A8D29F"/>
        <path d="M306.567 176.329C306.567 176.329 306.94 172.629 305.001 172.757C303.062 172.883 302.548 173.77 302.765 174.482C302.981 175.193 304.869 177.546 304.869 177.546L306.568 176.331L306.567 176.329Z" fill="#FFB27D"/>
        <path d="M336.789 183.741C336.789 183.741 334.643 186.258 330.026 189.161C329.589 189.437 329.131 189.714 328.65 189.994C326.857 191.035 324.754 192.103 322.325 193.099C322.058 193.208 321.744 193.329 321.39 193.456C318.623 194.453 313.533 195.866 310.379 195.993C310.105 196.005 309.844 196.005 309.602 195.995C304.801 195.801 304.215 177.213 304.215 177.213C304.215 177.213 304.749 175.353 307.216 175.822C307.216 175.822 310.963 186.321 312.305 187.177C313.496 187.937 320.351 177.574 326.251 176.849C330.222 176.36 336.789 183.746 336.789 183.746V183.741Z" fill="#739AF0"/>
        <path d="M323.888 274.391C323.888 274.391 324.243 278.483 323.375 278.909L312.277 278.886C312.277 278.886 312.277 277.607 314.06 277.324C315.843 277.041 318.974 275.999 319.504 273.681C320.033 271.363 323.888 274.391 323.888 274.391Z" fill="#1C3154"/>
        <path d="M315.743 224.865C315.797 227.541 315.927 230.302 316.179 232.782C317.378 244.553 317.8 258.774 317.972 261.516C318.271 266.276 319.214 273.88 319.214 273.88C319.214 273.88 321.083 275.65 324.727 274.805C324.727 274.805 327.747 263.855 328.421 252.322C328.431 252.134 328.441 251.943 328.451 251.753C328.529 250.228 328.61 248.486 328.695 246.606C329.249 234.016 329.853 215.312 329.853 215.312L315.927 212.05C315.927 212.05 315.607 218.196 315.743 224.868V224.865Z" fill="#2A4E96"/>
        <path d="M315.743 224.865C319.272 230.939 325.999 242.13 328.695 246.603C329.249 234.012 329.853 215.308 329.853 215.308L315.927 212.046C315.927 212.046 315.607 218.193 315.743 224.865Z" fill="#233862"/>
        <path d="M321.386 193.455C318.619 194.452 313.529 195.864 310.375 195.992C310.858 195.902 314.371 195.207 315.669 193.644C317.064 191.961 319.413 187.394 321.282 189.259C322.162 190.136 321.903 191.87 321.386 193.456V193.455Z" fill="#6385DB"/>
        <path d="M344.787 222.886C344.787 222.886 345.652 226.268 345.51 227.019C345.368 227.771 344.523 230.315 343.997 230.947C343.471 231.578 343.449 228.203 343.099 227.672C342.749 227.142 342.938 228.932 342.658 229.079C342.377 229.225 341.909 227.723 341.701 227.19C341.494 226.657 342.448 224.802 342.538 223.472C342.626 222.143 344.786 222.886 344.786 222.886H344.787Z" fill="#FFB27D"/>
        <path d="M351.536 269.741C351.536 269.741 353.512 271.54 353.126 272.817C352.741 274.095 350.958 274.946 350.718 276.508C350.477 278.069 349.514 278.92 348.646 278.92H344.512C344.512 278.92 343.873 277.821 345.415 277.49C346.957 277.158 347.538 275.324 347.442 273.62C347.346 271.916 347.587 269.645 347.587 269.645L350.862 268.889L351.536 269.74V269.741Z" fill="#1C3154"/>
        <path d="M318.119 215.593C318.119 215.593 331.156 243.417 338.093 255.624C345.031 267.832 347.199 271.24 347.199 271.24C347.199 271.24 351.173 269.678 351.535 268.614C351.535 268.614 348.882 245.044 344.236 239.3C341.611 236.054 338.527 226.311 338.382 222.904C338.238 219.498 335.192 211.366 335.192 211.366L318.119 215.593Z" fill="#2A4E96"/>
        <path d="M315.689 214.146C315.689 214.146 319.934 218.703 327.147 220.079C330.313 220.684 336.739 221.268 338.333 219.019C338.707 218.491 338.71 217.71 338.627 216.489C338.413 213.318 337.613 207.186 341.223 194.864C341.56 193.718 341.786 192.618 341.917 191.57C342.706 185.266 342.619 180.508 337.138 177.967C328.922 174.158 325.55 177.291 325.55 177.291C325.55 177.291 321.181 179.328 319.184 185.263C315.191 197.134 315.688 214.144 315.688 214.144L315.689 214.146Z" fill="#739AF0"/>
        <path d="M332.205 176.92C330.822 177.9 328.388 178.478 326.069 177.321C325.89 177.231 325.913 176.008 325.958 174.805C326.002 173.626 326.114 172.467 326.114 172.467L329.037 170.553L331.826 168.728C331.492 175.006 332.83 176.475 332.206 176.918L332.205 176.92Z" fill="#FFB27D"/>
        <path d="M331.334 171.32C331.334 171.32 329.437 174.16 325.957 174.805C326.002 173.626 326.128 172.326 326.128 172.326L329.036 170.555L331.336 171.321L331.334 171.32Z" fill="#ED985F"/>
        <path d="M323.495 171.73C323.495 171.73 330.567 174.363 332.141 170.016C333.715 165.669 335.687 163.083 331.238 161.218C326.789 159.354 325.307 160.698 324.422 162.021C323.537 163.345 321.696 170.934 323.495 171.73Z" fill="#FFB27D"/>
        <path d="M336.548 161.707C336.629 161.644 336.794 161.612 337.139 161.662C337.139 161.662 336.9 161.438 336.538 161.357C336.56 160.87 336.473 159.985 335.7 159.622C335.7 159.622 336.091 159.941 336.05 160.455C335.298 159.302 333.414 158.199 327.726 157.537C320.229 156.664 321.728 161.857 322.573 162.608C323.418 163.358 326.128 162.291 327.881 162.783C329.634 163.275 329.464 165.908 329.997 166.378C330.532 166.85 330.417 166.179 331.307 165.972C332.197 165.765 332.008 167.132 331.598 167.963C331.187 168.794 332.081 170.175 332.081 170.175L333.457 169.18C334.833 168.185 336.925 163.232 336.628 162.03C336.601 161.924 336.576 161.816 336.55 161.708L336.548 161.707Z" fill="#2C3A64"/>
        <path d="M338.625 216.489C338.411 213.318 337.611 207.186 341.222 194.864C341.559 193.718 341.785 192.618 341.916 191.57C339.233 190.688 334.346 192.65 334.346 192.65C334.346 192.65 333.197 208.938 338.627 216.487L338.625 216.489Z" fill="#6385DB"/>
        <path d="M338.617 178.791C338.617 178.791 341.917 179.989 344.024 188.707C345.415 194.467 347.977 202.933 348.266 205.595C348.555 208.256 345.337 223.832 345.337 223.832C345.337 223.832 344.884 224.161 343.772 223.939C342.708 223.727 342.213 223.009 342.213 223.009C342.213 223.009 341.444 212.627 341.642 207.724C341.725 205.687 332.191 192.809 327.045 191.15C325.024 190.499 338.617 178.79 338.617 178.79V178.791Z" fill="#739AF0"/>
        <path d="M292.447 167.506C292.447 167.506 301.988 167.236 312.998 169.258L314.148 166.367L315.41 163.199C315.41 163.199 304.357 158.453 297.713 151.842L292.447 167.506Z" fill="#AEC3FF"/>
        <path d="M297.862 151.687L292.384 167.816C291.582 167.579 291.145 166.721 291.424 165.936L296.245 152.384C296.479 151.728 297.221 151.407 297.862 151.687Z" fill="#F99746"/>
        <path d="M303.091 179.51C304.155 179.722 305.201 179.026 305.415 177.965L307.301 168.551C307.513 167.488 306.816 166.446 305.752 166.233C304.686 166.021 303.642 166.716 303.428 167.778L301.542 177.192C301.33 178.255 302.027 179.296 303.091 179.51Z" fill="#F99746"/>
        <path d="M292.447 167.506C292.447 167.506 301.988 167.236 312.998 169.258L314.148 166.367C303.802 164.259 292.447 167.506 292.447 167.506Z" fill="#739AF0"/>
        <path d="M309.953 168.675L312.935 169.198C312.89 169.2 315.704 169.745 316.627 167.256C316.711 167.085 316.766 166.918 316.804 166.719C317.522 163.618 315.815 163.046 315.815 163.046L311.978 161.554L310.76 165.858V165.873L309.956 168.675H309.953Z" fill="#F99746"/>
        <path d="M309.953 168.675L312.935 169.198C312.89 169.2 315.704 169.745 316.627 167.256C314.94 166.032 310.758 165.857 310.758 165.857V165.871L309.955 168.673L309.953 168.675Z" fill="#ED7D2B"/>
        <path d="M303.347 170.395C303.347 170.395 304.806 170.975 304.537 171.616C304.27 172.257 302.416 173.138 302.124 172.633C301.831 172.128 302.429 169.856 303.347 170.397V170.395Z" fill="#FFB27D"/>
        <path d="M306.597 175.746C306.597 175.746 306.872 174.257 306.889 173.573C306.905 172.889 306.987 172.331 306.826 172.311C306.665 172.292 306.547 172.417 306.483 172.638C306.416 172.858 306.164 174.221 306.164 174.221L306.292 175.537L306.599 175.744L306.597 175.746Z" fill="#FFB27D"/>
        <path d="M162.346 174.774L154.084 272.773C154.084 272.773 153.219 278.713 157.648 278.725C164.931 278.741 193.901 278.725 193.901 278.725C193.901 278.725 188.42 277.488 188.486 274.706C188.564 271.427 198.143 173.846 198.143 173.846L162.346 174.774Z" fill="#7599EA"/>
        <path d="M196.63 276.986C195.198 276.991 167.152 277.037 160.058 276.946C156.967 276.908 156.424 274.204 156.4 272.455C156.386 271.617 156.494 270.996 156.494 270.996L156.708 268.45L157.256 261.958L157.739 256.222L158.362 248.851L158.885 242.63L164.757 172.994L200.553 172.066C200.553 172.066 196.519 212.904 193.665 242.628C193.347 245.94 193.045 249.116 192.764 252.08C192.673 253.028 192.586 253.952 192.5 254.853C192.236 257.664 191.994 260.248 191.783 262.528C191.672 263.73 191.569 264.846 191.477 265.87C191.114 269.879 190.9 272.439 190.896 272.926C190.896 273.209 190.92 273.474 190.966 273.719C191.554 276.842 196.092 276.983 196.63 276.983V276.986Z" fill="#90AFF4"/>
        <path d="M75.4141 121.445L95.5411 202.089L98.2968 213.132L107.67 250.693H281.822L247.272 121.445H75.4141Z" fill="#AEC3FF"/>
        <path d="M75.4159 121.445L72.627 122.373L103.965 253.498L107.673 250.693L75.4159 121.445Z" fill="#90AFF4"/>
        <path d="M103.965 253.498H277.045L281.824 250.693H107.674L103.965 253.498Z" fill="#7599EA"/>
        <path d="M164.744 278.763H202.219V276.986H164.744V278.763Z" fill="#AEC3FF"/>
        <path d="M192.694 253.498L192.5 254.856L157.258 261.958L157.741 256.222L157.972 253.498H192.694Z" fill="#5888DD"/>
        <path opacity="0.4" d="M196.629 276.986C195.197 276.991 167.151 277.038 160.057 276.946C156.966 276.908 156.423 274.204 156.398 272.455L191.475 265.873C191.111 269.882 190.897 272.442 190.894 272.929C190.894 273.212 190.917 273.477 190.963 273.722C191.551 276.845 196.09 276.986 196.628 276.986H196.629Z" fill="#5888DD"/>
        <path d="M75.4141 121.445H247.272L275.155 225.32H101.338L75.4141 121.445Z" fill="#AEC3FF"/>
        <path d="M267.051 220.818H106.14L100.389 197.969L98.1647 189.128L97.2582 185.523L91.3483 162.041L91.0412 160.819L87.9185 148.406L86.5207 142.854L85.3288 138.116L82.5332 127.011H241.124L244.193 138.116L245.503 142.854L247.037 148.406L257.149 184.994V184.996L258.005 188.096L258.404 189.535L265.386 214.793L267.051 220.818Z" fill="#739AF0"/>
        <path d="M244.193 138.116H85.3288L82.5332 127.009H241.125L244.193 138.116Z" fill="#607CF4"/>
        <path d="M89.0955 132.029C88.9046 131.262 89.3711 130.641 90.1397 130.641C90.9083 130.641 91.6869 131.262 91.8778 132.029C92.0687 132.795 91.6022 133.416 90.8336 133.416C90.065 133.416 89.2864 132.795 89.0955 132.029Z" fill="#E9F3FE"/>
        <path d="M93.7185 132.029C93.5276 131.262 93.9941 130.641 94.7627 130.641C95.5314 130.641 96.3099 131.262 96.5008 132.029C96.6918 132.795 96.2253 133.416 95.4566 133.416C94.688 133.416 93.9094 132.795 93.7185 132.029Z" fill="#E9F3FE"/>
        <path d="M98.7009 132.029C98.51 131.262 98.9765 130.641 99.7451 130.641C100.514 130.641 101.292 131.262 101.483 132.029C101.674 132.795 101.208 133.416 100.439 133.416C99.6704 133.416 98.8919 132.795 98.7009 132.029Z" fill="#E9F3FE"/>
        <path d="M209.576 62.4436C216.08 54.8262 226.109 39.5251 232.86 31.6908C234.25 30.0779 233.095 27.589 230.963 27.589H218.677L230.266 1.44805C230.76 0.331934 229.236 -0.557317 228.503 0.419698L200.48 37.8311C199.664 38.9224 200.557 40.4591 201.913 40.2918L215.823 38.5829L207.547 61.2596C207.092 62.5082 208.713 63.4537 209.576 62.4419V62.4436Z" fill="#F49123"/>
        <path d="M151.731 108.837C153.193 101.35 154.118 87.4469 155.664 79.7235C155.982 78.1338 154.281 76.9117 152.865 77.7065L144.704 82.2902L142.599 60.6038C142.509 59.6781 141.163 59.6566 141.043 60.579L136.46 95.8823C136.327 96.9123 137.496 97.5996 138.334 96.9819L146.931 90.6578L149.938 108.807C150.104 109.806 151.535 109.829 151.729 108.835L151.731 108.837Z" fill="#AEC3FF"/>
        <path d="M274.081 223.03L134.263 234.781C123.647 235.673 115.964 224.876 120.31 215.174L179.546 82.9344C184.039 72.9026 197.853 71.7418 203.966 80.8826L284.548 201.372C290.462 210.213 284.698 222.139 274.081 223.032V223.03Z" fill="#E6EFFF"/>
        <path d="M274.526 228.304L134.708 240.055C127.832 240.633 121.341 237.627 117.347 232.014C113.352 226.4 112.649 219.298 115.464 213.013L174.7 80.7734C177.61 74.2754 183.593 70.0395 190.703 69.4417C197.813 68.8439 204.422 72.0216 208.381 77.9433L288.964 198.432C292.794 204.159 293.292 211.279 290.295 217.479C287.299 223.679 281.404 227.726 274.528 228.304H274.526ZM191.594 79.9918C188.394 80.26 185.701 82.1677 184.391 85.0921L125.156 217.332C123.889 220.16 124.206 223.358 126.004 225.883C127.802 228.41 130.724 229.763 133.818 229.503L273.636 217.753C276.731 217.493 279.385 215.671 280.733 212.881C282.081 210.09 281.859 206.884 280.134 204.308L199.551 83.8187C197.768 81.1542 194.795 79.7235 191.594 79.9918Z" fill="#F49123"/>
        <path d="M188.316 202.798C188.164 201.001 188.344 199.312 188.86 197.732C189.375 196.154 190.172 194.756 191.252 193.541C192.333 192.327 193.671 191.335 195.27 190.57C196.867 189.807 198.701 189.335 200.771 189.161C202.84 188.987 204.742 189.144 206.472 189.628C208.204 190.113 209.704 190.866 210.973 191.883C212.243 192.902 213.262 194.147 214.034 195.616C214.806 197.088 215.267 198.721 215.42 200.519C215.571 202.316 215.388 203.992 214.872 205.543C214.356 207.096 213.557 208.479 212.477 209.695C211.396 210.91 210.043 211.902 208.418 212.669C206.792 213.435 204.945 213.907 202.875 214.081C200.804 214.255 198.917 214.098 197.215 213.611C195.512 213.124 194.026 212.371 192.756 211.352C191.488 210.335 190.467 209.105 189.698 207.659C188.928 206.215 188.468 204.594 188.316 202.798ZM209.932 177.566L189.791 179.258L181.141 110.855L206.987 108.683L209.932 177.566Z" fill="#F49123"/>
        <path d="M101.494 181.76C101.494 181.76 101.932 180.777 102.216 180.311C102.5 179.846 103.714 178.482 103.92 178.79C104.125 179.099 104.952 181.177 104.514 181.76C104.076 182.343 104.542 183.355 102.361 183.969L101.496 181.76H101.494Z" fill="#FFB27D"/>
        <path d="M77.2441 175.55C77.2441 175.55 88.4149 184.852 92.7694 183.991C96.1759 183.317 101.56 181.615 101.56 181.615C101.56 181.615 102.981 182.963 102.579 184.579C102.579 184.579 95.7194 191.017 90.832 191.058C83.1923 191.123 79.5899 187.283 79.5899 187.283L77.2441 175.552V175.55Z" fill="#ED7D2B"/>
        <path d="M57.6562 273.658C57.6562 273.658 57.98 278.876 58.9926 278.95C59.1271 278.96 64.1257 278.96 64.1257 278.96L70.5071 278.924C70.5071 278.924 70.4656 277.39 68.5996 277.066C66.7353 276.741 63.6127 275.435 62.4822 272.975C61.2935 270.389 57.6562 273.658 57.6562 273.658Z" fill="#113C68"/>
        <path d="M57.2866 274.019C57.2866 274.019 59.2256 274.666 60.7413 274.666C62.4313 274.666 63.9652 274.207 63.9652 274.207C63.9652 274.207 64.3304 270.685 66.3391 262.924C67.3933 258.855 68.8973 253.624 71.0372 247.124C76.9554 229.16 78.755 218.652 78.9193 217.657C78.9276 217.604 78.931 217.579 78.931 217.579L78.911 217.57L60.7147 209.602C60.7147 209.602 62.9641 234.36 60.0938 243.864C59.6921 245.192 59.3485 246.74 59.053 248.416C57.2401 258.738 57.2833 274.02 57.2833 274.02L57.2866 274.019Z" fill="#2A4E96"/>
        <path d="M66.3393 262.924C67.3934 258.855 68.8975 253.624 71.0373 247.124C76.9556 229.16 78.7551 218.652 78.9195 217.656C78.9178 217.627 78.9161 217.599 78.9128 217.569L60.7165 209.6C60.7165 209.6 62.9659 234.358 60.0956 243.862C59.6939 245.19 66.3376 262.924 66.3376 262.924H66.3393Z" fill="#233862"/>
        <path d="M71.8672 273.658C71.8672 273.658 72.1909 278.876 73.2036 278.95C73.338 278.96 78.3366 278.96 78.3366 278.96L84.718 278.924C84.718 278.924 84.6765 277.39 82.8105 277.066C80.9463 276.741 77.8236 275.435 76.6931 272.975C75.5044 270.389 71.8672 273.658 71.8672 273.658Z" fill="#15447C"/>
        <path d="M82.1833 210.183C82.1833 210.183 82.419 226.226 79.6367 245.925C76.8544 265.625 77.7973 273.986 77.7973 273.986C77.7973 273.986 75.8849 274.603 75.1146 274.666C73.149 274.825 71.1337 274.206 71.1337 274.206C71.1337 274.206 67.1511 254.5 68.2933 244.64C69.4354 234.781 62.8398 210.807 62.8398 210.807L82.1849 210.182L82.1833 210.183Z" fill="#2A4E96"/>
        <path d="M69.281 172.641L72.8319 174.964L76.0674 174.052C76.6584 173.211 76.8161 171.588 76.8244 170.183C76.8327 168.789 76.6966 167.607 76.6966 167.607L71.9039 165.497L70.116 164.711C70.121 164.739 70.2554 166.174 70.2737 166.385C70.6074 170.109 69.2793 172.641 69.2793 172.641H69.281Z" fill="#FFB27D"/>
        <path d="M82.265 214.644C77.8442 218.902 61.1137 218.186 60.0828 213.495C59.0535 208.804 61.2067 173.562 68.7585 171.348C70.3256 170.889 78.7041 173.59 78.7041 173.59C78.7041 173.59 80.0189 177.021 80.8805 181.388C81.0482 182.237 81.5362 191.244 81.9014 199.669C82.0724 203.611 82.2169 207.424 82.2899 210.218C82.3563 212.834 82.3596 214.553 82.265 214.646V214.644Z" fill="#F99746"/>
        <path d="M70.6342 166.449C71.607 168.089 75.0169 169.524 76.8247 170.182C76.833 168.788 76.6969 167.605 76.6969 167.605L71.9042 165.496C70.845 165.482 70.1993 165.717 70.6325 166.449H70.6342Z" fill="#FCA56B"/>
        <path d="M78.5064 167.314C78.5064 167.314 70.7853 169.717 69.3593 164.898C67.9333 160.079 65.976 157.143 70.8451 155.406C75.7142 153.669 77.2215 155.224 78.0898 156.717C78.958 158.211 80.4786 166.567 78.5064 167.315V167.314Z" fill="#FFB27D"/>
        <path d="M77.7878 156.252C77.7878 156.252 77.2948 161.861 76.1327 161.617C74.9706 161.374 73.6907 160.852 73.86 162.543C74.031 164.234 72.7295 167.132 71.0528 167.125C67.457 167.11 63.5508 157.906 64.5353 156.161C67.3757 151.125 81.8932 150.108 77.7878 156.252Z" fill="#163560"/>
        <path d="M76.6499 171.469C76.5503 171.427 70.9027 168.9 70.2419 169.117C69.5812 169.334 68.7578 171.348 68.7578 171.348L78.7035 173.59C78.7035 173.59 77.9199 172.002 76.6499 171.469Z" fill="#ED7D2B"/>
        <path d="M111.817 198.762L80.1558 228.498C79.5349 229.081 78.753 229.266 78.416 228.91L77.3469 227.778C77.0115 227.422 77.244 226.653 77.8648 226.07L109.526 196.334C110.147 195.751 110.929 195.566 111.266 195.922L112.335 197.055C112.67 197.411 112.438 198.179 111.817 198.762Z" fill="#1F4D84"/>
        <path d="M94.0491 215.449L80.1558 228.498C79.5349 229.081 78.753 229.266 78.416 228.91L77.3469 227.778C77.0115 227.422 77.244 226.653 77.8648 226.07L91.7582 213.021L94.0491 215.447V215.449Z" fill="#739AF0"/>
        <path d="M104.542 187.535C105.757 191.53 108.008 195.273 111.277 198.34C121.259 207.707 136.965 207.229 146.356 197.273C155.623 187.447 155.279 172.063 145.674 162.657C145.546 162.531 145.417 162.405 145.284 162.283C135.304 152.915 119.599 153.39 110.208 163.348C103.891 170.044 102.039 179.326 104.54 187.535H104.542ZM111.636 164.687C117.101 158.893 124.888 156.583 132.201 157.847C136.462 158.582 140.559 160.529 143.944 163.705C153.138 172.333 153.578 186.761 144.929 195.934C141.745 199.31 137.774 201.501 133.567 202.496C126.344 204.202 118.429 202.372 112.62 196.919C103.426 188.29 102.985 173.861 111.636 164.689V164.687Z" fill="#1F4D84"/>
        <g opacity="0.5">
            <path d="M144.929 195.935C141.745 199.312 137.772 201.503 133.567 202.498C126.344 204.202 118.431 202.372 112.62 196.919C103.427 188.291 102.985 173.861 111.636 164.689C117.101 158.895 124.888 156.585 132.201 157.848C136.463 158.584 140.561 160.531 143.945 163.707C153.138 172.335 153.58 186.765 144.929 195.935Z" fill="#B3CFF9"/>
        </g>
        <path d="M82.2882 210.216C64.8423 197.816 66.2932 181.268 66.2932 181.268C72.6696 184.855 78.9962 192.787 81.8997 199.668C82.0707 203.609 82.2152 207.423 82.2882 210.216Z" fill="#ED7D2B"/>
        <path d="M99.5116 204.57C100.742 204.286 101.569 203.359 101.739 203.197C101.739 203.197 102.264 203.233 101.653 204.349C101.602 204.442 101.489 204.682 101.402 204.853C101.368 204.922 101.416 205.003 101.494 205.008C102.204 205.063 102.918 204.782 104.284 205.066C104.474 205.106 104.528 205.557 104.52 205.749C104.509 206.073 104.532 206.439 104.153 206.938C103.932 207.227 103.318 207.539 101.949 208.088C101.427 208.297 99.935 208.14 98.5372 207.661L97.4199 207.145L97.7669 205.075L98.0474 205.133C98.4923 205.225 99.0452 204.684 99.51 204.57H99.5116Z" fill="#FFB27D"/>
        <path d="M71.7113 173.59C71.7113 173.59 56.0366 176.567 76.323 199.211C79.5668 202.832 97.0975 207.547 97.0975 207.547C97.0975 207.547 98.8754 206.038 97.979 204.816C97.979 204.816 85.9084 196.073 83.2539 194.683C82.0586 194.057 78.6173 180.729 76.67 177.519C74.7227 174.31 71.7113 173.59 71.7113 173.59Z" fill="#F99746"/>
        <path d="M101.92 182.035C101.92 182.035 102.433 181.058 102.532 180.542C102.632 180.027 103.278 178.523 103.543 178.258C103.649 178.152 104.001 178.289 104.043 178.745C104.061 178.944 103.963 179.305 103.791 179.733C103.51 180.434 103.556 181.383 103.459 181.699C103.216 182.487 101.918 182.035 101.918 182.035H101.92Z" fill="#FFB27D"/>
        <path d="M108.154 143.239C107.887 143.544 107.854 143.884 107.985 144.248C107.985 144.248 107.985 144.251 107.988 144.253C108.232 144.925 109.037 145.677 109.973 146.427C110.093 146.523 110.199 146.611 110.292 146.692C110.317 146.714 110.34 146.735 110.364 146.757C110.382 146.773 110.4 146.79 110.418 146.806C111.292 147.61 110.963 147.793 112.53 149.423C114.318 151.279 115.548 151.314 115.898 151.143C116.196 150.999 115.593 150.016 114.926 148.908C114.894 148.855 114.861 148.8 114.829 148.747C114.74 148.6 114.652 148.451 114.564 148.303C113.782 146.972 113.983 146.348 114.169 144.366C114.32 142.756 116.684 135.37 116.684 135.37L113.257 134.781C113.257 134.781 112.223 140.661 111.086 141.567C110.478 142.049 109.798 142.055 109.213 142.413C109.172 142.438 109.132 142.463 109.092 142.489C108.76 142.71 108.421 142.935 108.154 143.241V143.239Z" fill="#FFB27D"/>
        <path d="M108.154 143.239C107.887 143.544 107.854 143.884 107.985 144.248C107.985 144.248 107.985 144.251 107.988 144.253C108.287 144.925 109.072 145.672 109.977 146.421C110.095 146.518 110.199 146.608 110.292 146.692C110.317 146.714 110.34 146.735 110.364 146.757C110.382 146.773 110.4 146.79 110.418 146.806C111.292 147.609 110.963 147.793 112.53 149.423C114.318 151.279 115.548 151.314 115.898 151.143C116.196 150.999 115.593 150.016 114.926 148.908C114.577 148.78 112.96 148.125 111.738 146.563C110.701 145.241 109.997 143.559 109.291 142.524C109.213 142.509 109.167 142.501 109.09 142.486C108.758 142.706 108.42 142.931 108.152 143.238L108.154 143.239Z" fill="#233862"/>
        <path d="M135.786 141.115C135.997 142.17 137.506 141.994 139.189 141.585C140.874 141.174 140.474 141.663 142.871 141.4C145.269 141.136 145.996 140.244 146.069 139.889C146.135 139.573 144.95 139.462 143.68 139.316C143.52 139.298 143.359 139.278 143.198 139.259C141.764 139.078 140.713 138.154 139.41 137.396C138.177 136.677 136.336 133.749 136.336 133.749L133.047 134.007C133.047 134.007 134.772 136.622 135.439 137.758C135.831 138.427 135.811 139.312 135.761 139.994C135.736 140.365 135.711 140.744 135.786 141.116V141.115Z" fill="#FFB27D"/>
        <path d="M139.19 141.585C140.875 141.174 140.475 141.663 142.872 141.4C145.269 141.136 145.996 140.244 146.069 139.889C146.135 139.573 144.95 139.462 143.68 139.316L143.669 139.341C143.669 139.341 142.231 140.358 140.235 140.404C138.609 140.444 136.887 139.977 135.763 139.995C135.738 140.366 135.713 140.746 135.788 141.118C135.999 142.173 137.508 141.997 139.191 141.588L139.19 141.585Z" fill="#233862"/>
        <path d="M123.194 120.139L120.117 128.387L116.102 139.157C116.102 139.157 115.013 139.262 113.97 138.962C113.011 138.686 111.908 137.96 111.908 137.96C111.908 137.96 113.027 126.393 115.107 119.755C115.64 118.051 116.238 116.673 116.897 115.896C120.121 112.099 123.194 120.135 123.194 120.135V120.139Z" fill="#233862"/>
        <path d="M120.07 107.035C118.32 106.472 113.755 107.024 108.803 107.916C107.435 108.163 106.055 107.539 105.335 106.353L92.8743 105.378C92.8743 105.378 88.7589 114.472 91.0083 120.27C91.3387 121.122 92.0642 121.654 93.1864 121.672C93.1864 121.672 100.717 121.685 103.125 121.685C109.323 121.687 116.242 117.441 117.137 117.262C118.601 116.971 119.187 119.894 120.651 122.962C122.115 126.031 134.417 136.556 134.417 136.556C134.417 136.556 136.307 136.576 137.089 136.283C137.929 135.968 138.236 135.561 138.236 135.561C138.236 135.561 124.612 108.497 120.07 107.034V107.035Z" fill="#2A4E96"/>
        <path d="M104.027 69.8507C103.102 69.3009 102.199 69.2165 101.646 69.2314C101.261 69.243 100.878 69.2165 100.498 69.1618C95.7415 68.4647 92.8761 75.3551 92.7068 77.4367C92.398 81.2586 90.3694 81.8415 87.5157 84.2344C81.7917 89.0333 83.4684 94.6106 86.596 96.6855C88.4736 97.9291 91.7821 96.6855 91.7821 96.6855C91.8286 96.7186 100.833 91.6696 105.27 89.2337C111.922 88.2931 106.698 71.4371 104.027 69.8491V69.8507Z" fill="#233862"/>
        <path d="M101.554 87.1588C101.574 87.172 99.3709 87.9205 97.8984 87.2697C96.5123 86.6587 95.4647 84.3834 95.4847 84.3834C96.1155 84.3552 96.8077 83.8055 97.3406 80.1988L97.6096 80.2948L102.122 81.916C102.122 81.916 101.795 83.36 101.582 84.7361C101.403 85.8903 101.308 86.9965 101.554 87.1604V87.1588Z" fill="#FFB27D"/>
        <path d="M102.12 81.916C102.12 81.916 101.939 82.6959 101.676 84.1515C99.0849 83.6845 97.9726 81.6924 97.6074 80.2948L102.12 81.916Z" fill="#ED975D"/>
        <path d="M102.792 83.3501C102.792 83.3501 96.6181 82.5205 97.1942 78.6935C97.7702 74.8666 97.3619 72.1806 101.329 72.5483C105.295 72.9159 105.84 74.4907 105.959 75.8155C106.079 77.1402 104.414 83.4677 102.794 83.3501H102.792Z" fill="#FFB27D"/>
        <path d="M105.736 73.7158C105.736 73.7158 101.781 77.1121 99.0937 77.3854C96.4044 77.6586 95.3867 76.7975 95.3867 76.7975C95.3867 76.7975 96.9721 75.486 97.9267 73.368C98.2072 72.7437 98.8015 72.3148 99.4855 72.2569C101.405 72.0929 105.045 72.0184 105.736 73.7158Z" fill="#233862"/>
        <path d="M103.262 73.4111C103.262 73.4111 104.583 74.6861 105 75.6334C105.402 76.5458 105.521 78.73 105.201 79.5961C105.201 79.5961 107.263 75.6002 106.097 73.6595C104.86 71.5994 103.262 73.4111 103.262 73.4111Z" fill="#233862"/>
        <path d="M106.748 96.2351C106.557 108.703 106.693 106.098 105.325 107.28C104.995 107.565 104.07 107.734 102.85 107.815C99.0165 108.067 92.2798 107.436 92.0242 106.729C91.141 104.283 91.7652 103.525 91.0298 99.7821C90.9534 99.3847 90.8588 98.9525 90.7459 98.4838C90.1317 95.9138 89.534 95.0378 91.7171 90.9095C93.6976 87.1687 95.7528 84.1333 95.9387 84.2426C101.158 87.3359 101.58 84.7377 101.58 84.7377C101.58 84.7377 106.842 90.1544 106.748 96.2367V96.2351Z" fill="#F99746"/>
        <path d="M120.117 128.387L116.102 139.158C116.102 139.158 115.013 139.262 113.97 138.962C113.011 138.686 111.908 137.96 111.908 137.96C111.908 137.96 113.027 126.393 115.107 119.755L120.117 128.385V128.387Z" fill="#2A4E96"/>
        <path d="M101.582 84.7344C101.582 84.7344 106.952 85.6452 107.583 87.952C108.212 90.2587 110.284 104.261 110.284 104.261C110.284 104.261 108.769 105.737 107.509 104.478C106.247 103.22 103.543 91.3765 103.543 91.3765L101.582 84.7361V84.7344Z" fill="#F99746"/>
        <path d="M102.853 107.814C99.0199 108.065 92.2833 107.434 92.0276 106.727C91.1444 104.281 91.7686 103.523 91.0332 99.7805L92.8842 94.5891C92.8842 94.5891 92.5771 100.372 94.2853 101.892C95.9919 103.414 101.283 104.83 102.114 106.101C102.514 106.711 102.778 107.323 102.855 107.814H102.853Z" fill="#ED7D2B"/>
        <path d="M98.4606 105.381C98.4606 105.381 102.242 104.543 102.852 104.878C103.461 105.212 104.952 106.432 105.139 106.856C105.325 107.28 103.781 106.533 103.278 106.399C102.953 106.312 102.096 106.608 101.671 106.75C99.8867 107.343 98.7927 107.037 98.7927 107.037L98.459 105.381H98.4606Z" fill="#FFB27D"/>
        <path d="M95.9365 84.2376C95.9365 84.2376 90.86 84.5473 89.1003 86.9931C86.6251 90.4342 81.7112 105.103 84.5234 107.06C87.6908 109.264 98.9397 107.641 98.9397 107.641C98.9397 107.641 99.3148 105.896 98.7936 105.051C98.2723 104.207 89.8091 102.841 89.419 102.45C89.0289 102.061 94.3047 94.8523 94.3047 93.6832C94.3047 92.5141 95.9332 84.2376 95.9332 84.2376H95.9365Z" fill="#F99746"/>
        <path d="M103.035 105.927C103.035 105.927 103.827 106.947 104.086 107.068C104.345 107.191 104.514 107.159 104.514 107.159L103.981 106.201L103.035 105.926V105.927Z" fill="#FFB27D"/>
        <path d="M102.853 104.878L105.156 105.994C105.156 105.994 105.058 106.154 104.774 106.141C104.49 106.128 103.775 105.866 103.775 105.866L102.852 104.878H102.853Z" fill="#FFB27D"/>
        <path d="M122.522 107.507L98.4609 107.499V108.029L122.308 108.037L122.522 107.507Z" fill="#233862"/>
        <path d="M104.088 108.03L122.309 108.037L127.796 94.4417L109.603 94.4351L104.088 108.03Z" fill="#233862"/>
        <path d="M117.713 101.236C117.851 100.758 117.574 100.37 117.094 100.37C116.614 100.37 116.115 100.758 115.977 101.234C115.839 101.713 116.116 102.101 116.596 102.101C117.076 102.101 117.575 101.713 117.713 101.236Z" fill="white"/>
        <path d="M241.03 159.302C238.575 158.489 236.877 159.516 236.877 159.516C229.81 159.806 229.547 167.032 230.376 169.722C231.541 173.499 227.418 176.476 231.991 178.005C237.297 179.778 241.749 177.673 241.445 178.587C258.224 179.906 246.613 161.152 241.03 159.302Z" fill="#163560"/>
        <path d="M233.703 194.448C233.703 194.448 233.031 195.775 231.814 197.298C230.346 199.133 222.64 194.779 223.304 194.448C223.968 194.117 227.84 182.989 228.763 181.591C229.686 180.194 232.826 179.467 232.826 179.467L233.703 194.448Z" fill="#F99746"/>
        <path d="M259.706 269.496C259.706 269.496 261.833 271.432 261.418 272.808C261.003 274.184 259.084 275.102 258.825 276.782C258.566 278.463 257.528 279.381 256.593 279.381H252.141C252.141 279.381 251.454 278.197 253.114 277.841C254.774 277.485 255.4 275.509 255.295 273.674C255.191 271.839 255.451 269.395 255.451 269.395L258.979 268.58L259.706 269.498V269.496Z" fill="#1F447F"/>
        <path d="M241.703 270.319C241.703 270.319 242.468 278.521 241.535 278.98L229.586 278.955C229.586 278.955 229.586 277.579 231.505 277.274C233.424 276.968 236.796 275.847 237.367 273.351C237.938 270.856 241.703 270.319 241.703 270.319Z" fill="#1C3154"/>
        <path d="M250.895 188.916C250.895 188.916 251.073 191.42 250.895 194.425C250.778 196.417 248.757 199.085 248.47 199.746C247.751 201.402 246.939 201.67 246.939 201.67L240.666 203.203L238.46 203.741L231.502 201.668C231.502 201.668 228.333 192.847 229.606 187.881C230.878 182.915 232.822 179.465 232.822 179.465C232.822 179.465 237.55 178.405 245.89 178.995L250.894 188.914L250.895 188.916Z" fill="#F99746"/>
        <path d="M251.491 194.495C251.373 196.487 248.755 199.085 248.468 199.746C247.749 201.402 246.937 201.67 246.937 201.67L240.663 203.203L239.531 199.964C239.531 199.964 248.698 197.561 247.224 189.098L251.491 194.493V194.495Z" fill="#ED7D2B"/>
        <path d="M231.946 203.008C231.946 203.008 228.501 213.704 228.279 217.347C228.058 220.988 234.864 269.082 235.138 270.76C235.396 272.341 237.366 273.35 237.366 273.35C237.366 273.35 238.272 273.883 239.564 273.519C241.357 273.013 241.925 272.409 241.925 272.409C241.925 272.409 244.606 238.109 242.947 225.857C241.287 213.604 241.269 203.008 241.269 203.008H231.948H231.946Z" fill="#233862"/>
        <path d="M246.544 202.69C246.544 202.69 251.8 209.723 252.29 214.479C252.779 219.233 253.058 235.933 254.102 240.568C255.147 245.203 258.982 254.124 258.982 268.581C258.982 268.581 258.583 269.409 257.353 269.851C256.959 269.991 256.432 270.129 255.92 270.246C254.906 270.478 253.863 269.983 253.4 269.054C250.807 263.848 242.55 247.053 242.127 243.567C241.828 241.1 239.22 224.772 233.531 215.192C232.164 212.892 234.369 203.745 234.369 203.745L246.546 202.692L246.544 202.69Z" fill="#2A4E96"/>
        <path d="M231.395 201.357C231.395 201.357 232.778 202.448 235.525 202.503C238.807 202.569 246.938 201.668 246.938 201.668C246.938 201.668 247.3 202.675 247.285 203.738C247.285 203.738 237.353 206.237 231.599 204.103C231.599 204.103 230.857 202.71 231.395 201.355V201.357Z" fill="#233862"/>
        <path d="M234.74 205.325C233.915 205.325 233.242 204.566 233.242 203.634C233.242 202.702 233.915 201.943 234.74 201.943C235.565 201.943 236.237 202.702 236.237 203.634C236.237 204.566 235.565 205.325 234.74 205.325ZM234.74 202.438C234.187 202.438 233.739 202.975 233.739 203.634C233.739 204.293 234.188 204.829 234.74 204.829C235.291 204.829 235.741 204.293 235.741 203.634C235.741 202.975 235.292 202.438 234.74 202.438Z" fill="white"/>
        <path d="M241.914 208.4L226.987 208.539L219.643 191.32L234.57 191.181L241.914 208.4Z" fill="#B5453C"/>
        <path d="M239.961 200.618L238.867 199.277C238.867 199.277 238.127 198.618 237.589 198.507C237.051 198.394 235.917 197.742 235.808 197.926C235.698 198.11 236.679 199.421 236.679 199.421L236.729 199.428C235.602 200.274 232.757 199.527 232.406 199.871C231.981 200.289 232.992 201.193 233.535 201.701C234.078 202.208 235.63 203.054 236.84 202.68C237.725 202.405 239.116 202.125 239.116 202.125L239.961 200.618Z" fill="#FFB27D"/>
        <path d="M220.766 194.349C220.766 194.349 221.704 193.286 222.302 193.796C222.899 194.306 222.145 196.816 221.576 196.561C221.007 196.306 220.439 194.662 220.766 194.349Z" fill="#FFB27D"/>
        <path d="M245.894 178.997C245.894 178.997 250.168 179.467 253.234 185.024C255.652 189.408 259.786 197.487 258.226 199.769C257.542 200.771 250.976 204.336 238.314 202.299C238.314 202.299 238.514 200.705 239.535 199.964C239.535 199.964 250.191 196.551 250.173 195.872C250.138 194.589 248.733 193.48 242.78 187.54C241.162 185.925 245.893 178.995 245.893 178.995L245.894 178.997Z" fill="#F99746"/>
        <path d="M242.605 179.222C242.751 179.025 242.563 178.399 242.328 177.233C242.115 176.176 241.755 174.542 241.762 172.704L241.446 172.757L236.15 173.646C236.15 173.646 236.272 175.059 236.275 176.39C236.275 176.402 236.275 176.412 236.275 176.423C236.282 177.579 235.963 179.326 236.353 179.553C238.36 180.725 242.039 179.979 242.605 179.222Z" fill="#FFB27D"/>
        <path d="M236.152 173.646C236.152 173.646 236.257 175.638 236.262 176.968C239.225 176.897 240.807 174.027 241.45 172.757L236.154 173.646H236.152Z" fill="#E8945B"/>
        <path d="M235.83 176.021C235.83 176.021 242.772 175.034 242.09 170.73C241.408 166.426 241.844 163.401 237.382 163.849C232.919 164.297 232.322 166.075 232.199 167.567C232.076 169.059 234.005 176.166 235.83 176.021Z" fill="#FFB27D"/>
        <path d="M234.213 164.245C234.213 164.245 236.915 168.991 239.943 169.276C242.973 169.559 244.11 168.582 244.11 168.582C244.11 168.582 241.595 167.168 240.639 164.154C240.639 164.154 235.258 161.616 234.211 164.245H234.213Z" fill="#163560"/>
        <path d="M234.749 164.581C234.749 164.581 233.427 165.33 233.011 166.444C232.604 167.534 232.333 168.937 232.705 170.543C232.705 170.543 230.728 167.339 232.025 165.143C233.398 162.815 234.749 164.581 234.749 164.581Z" fill="#163560"/>
        <path d="M2.84666 278.763H387.713C387.979 278.763 388.195 278.978 388.195 279.243V279.52C388.195 279.785 387.979 280 387.713 280H2.84666C2.58105 280 2.36523 279.785 2.36523 279.52V279.243C2.36523 278.978 2.58105 278.763 2.84666 278.763Z" fill="#233862"/>
    </svg>

</template>

<style scoped>

</style>
