<template>
    <div class="header">
        <div class="container">
            <burger-icon class="header-burger" @click="menuHandler"/>
            <div class="header-logo">
                <router-link :to="{name: 'Main'}" class="button" @click="scrollToId('#hero')">
                    <logo-semtax/>
                </router-link>
                <lang-select class="lang-desktop"/>
            </div>

            <div class="header-nav">
                <div @click="scrollToId('#accountingServices')" class="header-nav__item">
                    {{ $gettext('Бухгалтерські послуги') }}
                </div>
                <div @click="scrollToId('#hrServices')" class="header-nav__item">
                    {{ $gettext('Кадрові послуги') }}
                </div>
                <div @click="scrollToId('#financialServices')" class="header-nav__item">
                    {{ $gettext('Фінансові послуги') }}
                </div>
                <div @click="scrollToId('#legalServices')" class="header-nav__item">
                    {{ $gettext('Юридичні послуги') }}
                </div>
                <div @click="scrollToId('#contacts')" class="header-nav__item">
                    {{ $gettext('Контакти') }}
                </div>
            </div>

            <a href="tel:+380667156372" class="header-contacts">
                <contacts-icon/>
                <span>+380 66 715 63 72</span>
            </a>

            <div v-if="isMenuOpen" class="header-menu">
                <div class="header-menu_close-lang">
                    <div @click="menuHandler" class="close">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_23_7313)">
                                <path d="M15.4003 30.2495L30.2496 15.4003L28.5997 13.7504L13.7504 28.5996L15.4003 30.2495Z"
                                      fill="#015AB4"/>
                                <path d="M13.75 15.4004L28.5992 30.2496L30.2492 28.5997L15.3999 13.7505L13.75 15.4004Z"
                                      fill="#015AB4"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_23_7313">
                                    <rect width="28" height="28" fill="white" transform="translate(8 8)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <lang-select class="lang-mobile" :is-mobile="true"/>
                </div>

                <div class="navigation">
                    <div class="navigation_block">
                        <div @click="scrollToIdMobile('#accountingServices')" class="navigation--item">
                            <features-item1/>
                            <div class="title" v-html="$gettext('Бухгалтерські <br>послуги')"/>
                        </div>
                        <div @click="scrollToIdMobile('#hrServices')" class="navigation--item">
                            <features-item2/>
                            <div class="title" v-html="$gettext('Кадрові <br>послуги')"/>
                        </div>
                    </div>
                    <div class="navigation_block">
                        <div @click="scrollToIdMobile('#financialServices')" class="navigation--item">
                            <features-item3/>
                            <div class="title" v-html="$gettext('Фінансові <br>послуги')"/>
                        </div>
                        <div @click="scrollToIdMobile('#legalServices')" class="navigation--item">
                            <features-item4/>
                            <div class="title" v-html="$gettext('Юридичні <br>послуги')"/>
                        </div>
                    </div>
                    <div class="navigation_block">
                        <div class="navigation--item">
                            <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_0_341)">
                                    <path d="M21.3845 72C46.4063 71.7094 77.743 39.5717 71.0106 14.2683C69.6468 6.85021 56.4953 -5.94743 47.944 3.13815C44.544 6.74012 40.1654 9.54519 40.278 15.1151C40.2534 18.6289 41.9317 21.3457 44.4732 23.6213C47.3509 26.4992 47.6172 31.0766 45.0925 34.2687C41.9839 38.2028 38.2654 41.9209 34.3309 45.0307C31.1386 47.5551 26.5613 47.289 23.6834 44.4111L22.5186 43.2465C20.5645 41.2925 17.9573 40.216 15.177 40.216C9.61378 40.0998 6.79156 44.4905 3.19971 47.882C-5.87208 56.5102 6.88014 69.5349 14.33 70.9486C16.5032 71.6506 18.8688 72 21.3845 72ZM55.0342 3.09161C60.2206 2.88802 67.1376 10.4152 67.9743 14.8667C73.4238 35.6103 51.73 59.4788 33.0668 66.5412C23.9809 69.7902 15.6482 70.2504 8.78764 64.4806C4.21427 60.5653 0.684418 54.7606 5.38686 50.0693L10.0225 45.4339C12.72 42.6196 17.6345 42.6204 20.3314 45.434L21.4963 46.5986C25.4838 50.5863 31.8266 50.9552 36.2498 47.457C38.3101 45.8273 40.315 44.0407 42.2087 42.1469C44.1034 40.2522 45.89 38.2472 47.5188 36.1876C51.017 31.7647 50.648 25.4222 46.6605 21.4343L45.4958 20.2696C42.6814 17.5744 42.6823 12.6562 45.4958 9.9608L50.1314 5.32514C51.624 3.83242 53.243 3.09161 55.0342 3.09161Z"
                                          fill="black"/>
                                    <path d="M14.0537 28.6552C14.7673 28.6552 15.4087 28.1587 15.5645 27.4327C16.1936 24.4995 17.6474 21.8278 19.7686 19.7068C21.9217 17.5535 24.6364 16.0906 27.6189 15.4762C28.4555 15.3038 28.9939 14.4859 28.8217 13.6494C28.6492 12.8128 27.8313 12.2743 26.9949 12.4467C23.4184 13.1833 20.1633 14.9375 17.5815 17.5195C15.0379 20.063 13.2945 23.2666 12.5401 26.7842C12.3609 27.6192 12.8927 28.4415 13.7278 28.6207C13.8371 28.644 13.9463 28.6552 14.0537 28.6552Z"
                                          fill="#FF7438"/>
                                    <path d="M5.31658 37.3446C5.41753 37.3446 5.51988 37.3348 5.62266 37.3141C6.46006 37.146 7.00263 36.3308 6.83447 35.4934C5.2235 27.4706 7.7163 19.2266 13.5023 13.4403C19.299 7.64361 27.5568 5.15348 35.5916 6.77977C36.429 6.95004 37.2448 6.40789 37.4142 5.57063C37.5836 4.73351 37.0423 3.91748 36.2052 3.74806C27.1511 1.91551 17.8468 4.72128 11.315 11.253C4.79538 17.773 1.98666 27.0623 3.80178 36.102C3.94941 36.8369 4.59503 37.3446 5.31658 37.3446Z"
                                          fill="#FF7438"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_341">
                                        <rect width="72" height="72" fill="white" transform="matrix(-1 0 0 1 72 0)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div class="title" v-html="$gettext('Звязатись <br>з нами')"/>
                        </div>
                    </div>
                </div>

                <div class="header-menu_contacts">
                    <div class="header-menu_contacts_block">
                        <a href="tel:+380667156372" class="header-menu_contacts-item">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_23_7451)">
                                    <path d="M12 3V13L15 10H21V3H12ZM19 8H14V5H19V8Z" fill="#FF6B2C"/>
                                    <path d="M20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.33 14.9 16.22 14.88 16.12 14.88C15.86 14.88 15.61 14.98 15.41 15.17L13.21 17.37C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.58C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.5C21 15.95 20.55 15.5 20 15.5ZM5.03 5H6.53C6.6 5.88 6.75 6.75 6.99 7.59L5.79 8.8C5.38 7.59 5.12 6.32 5.03 5ZM19 18.97C17.68 18.88 16.4 18.62 15.2 18.21L16.4 17.01C17.25 17.25 18.12 17.4 19 17.46V18.97Z"
                                          fill="#015AB4"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_23_7451">
                                        <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            +380 66 715 63 72
                        </a>
                        <div class="header-menu_contacts-item">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_23_7457)">
                                    <path d="M12 1.94995C6.48 1.94995 2 6.42995 2 11.95C2 17.47 6.48 21.95 12 21.95H17V19.95H12C7.66 19.95 4 16.29 4 11.95C4 7.60995 7.66 3.94995 12 3.94995C16.34 3.94995 20 7.60995 20 11.95V13.38C20 14.17 19.29 14.95 18.5 14.95C17.71 14.95 17 14.17 17 13.38V11.95C17 9.18995 14.76 6.94995 12 6.94995C9.24 6.94995 7 9.18995 7 11.95C7 14.71 9.24 16.95 12 16.95C13.38 16.95 14.64 16.39 15.54 15.48C16.19 16.37 17.31 16.95 18.5 16.95C20.47 16.95 22 15.35 22 13.38V11.95C22 6.42995 17.52 1.94995 12 1.94995ZM12 14.95C10.34 14.95 9 13.61 9 11.95C9 10.29 10.34 8.94995 12 8.94995C13.66 8.94995 15 10.29 15 11.95C15 13.61 13.66 14.95 12 14.95Z"
                                          fill="#015AB4"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_23_7457">
                                        <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            info@smtax.org.ua
                        </div>
                    </div>
                    <div class="header-menu_contacts_socials">
                        <a href="#">
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="52" y="52" width="52" height="52" rx="12" transform="rotate(-180 52 52)"
                                      fill="#015AB4"/>
                                <path d="M31.9991 14.005L28.8868 14C25.3903 14 23.1307 16.3183 23.1307 19.9064V22.6297H20.0015C19.7311 22.6297 19.5121 22.8489 19.5121 23.1193V27.065C19.5121 27.3354 19.7313 27.5544 20.0015 27.5544H23.1307V37.5106C23.1307 37.781 23.3497 38 23.6201 38H27.7028C27.9732 38 28.1922 37.7808 28.1922 37.5106V27.5544H31.851C32.1214 27.5544 32.3404 27.3354 32.3404 27.065L32.3419 23.1193C32.3419 22.9895 32.2902 22.8651 32.1986 22.7733C32.107 22.6814 31.9821 22.6297 31.8523 22.6297H28.1922V20.3212C28.1922 19.2116 28.4566 18.6483 29.902 18.6483L31.9986 18.6475C32.2687 18.6475 32.4877 18.4283 32.4877 18.1582V14.4944C32.4877 14.2245 32.269 14.0055 31.9991 14.005Z"
                                      fill="white"/>
                            </svg>
                        </a>
                        <a href="#">
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="52" y="52" width="52" height="52" rx="12" transform="rotate(-180 52 52)"
                                      fill="#015AB4"/>
                                <g clip-path="url(#clip0_0_1335)">
                                    <path d="M36.5635 15.7383C35.8654 15.1138 33.0731 13.0563 26.7905 13.0195C26.7905 13.0195 19.4056 12.5786 15.805 15.8853C13.7843 17.906 13.0862 20.8086 13.0127 24.4459C12.9392 28.0832 12.829 34.8802 19.4056 36.754V39.583C19.4056 39.583 19.3689 40.722 20.1037 40.9424C21.0222 41.2364 21.5366 40.3546 22.4183 39.4361C22.896 38.9217 23.5573 38.1869 24.0349 37.599C28.5173 37.9664 31.9709 37.1214 32.3751 36.9744C33.2936 36.6805 38.4005 36.0192 39.2456 29.2222C40.1273 22.168 38.8781 17.7223 36.5635 15.7383ZM37.335 28.6711C36.637 34.3659 32.4853 34.7333 31.7137 34.9537C31.3831 35.0639 28.3703 35.7987 24.5493 35.5783C24.5493 35.5783 21.7203 38.9952 20.8017 39.9137C20.6548 40.0607 20.5078 40.0974 20.3976 40.0974C20.2506 40.0607 20.2139 39.877 20.2139 39.6198C20.2139 39.2524 20.2506 34.917 20.2506 34.917C14.7028 33.3739 15.0335 27.5688 15.0702 24.5194C15.1437 21.4699 15.6948 19.0083 17.3849 17.3182C20.4343 14.5626 26.717 14.9668 26.717 14.9668C32.0076 15.0035 34.5427 16.5834 35.1306 17.1345C37.0411 18.8246 38.0331 22.8293 37.335 28.6711Z"
                                          fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M26.8819 22.0756C26.8923 21.8826 27.0572 21.7346 27.2502 21.745C27.9886 21.7849 28.5982 22.0081 29.0313 22.458C29.4624 22.9059 29.6724 23.5308 29.7119 24.2806C29.7221 24.4737 29.5738 24.6384 29.3808 24.6486C29.1878 24.6587 29.023 24.5105 29.0129 24.3174C28.9789 23.6711 28.8031 23.2304 28.5269 22.9435C28.2527 22.6586 27.8336 22.4776 27.2125 22.444C27.0194 22.4336 26.8714 22.2686 26.8819 22.0756Z"
                                          fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M26.4048 20.1565C26.42 19.9638 26.5886 19.8199 26.7813 19.8351C28.2459 19.9507 29.4381 20.4568 30.3185 21.4172L30.319 21.4177C31.1563 22.3348 31.5885 23.5273 31.5493 24.897C31.5438 25.0902 31.3827 25.2424 31.1895 25.2368C30.9963 25.2313 30.8441 25.0702 30.8496 24.877C30.884 23.6749 30.5079 22.6629 29.8023 21.89C29.066 21.0869 28.0538 20.6377 26.7262 20.5329C26.5335 20.5177 26.3896 20.3492 26.4048 20.1565Z"
                                          fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M25.7056 18.3837C25.7056 18.1904 25.8623 18.0337 26.0556 18.0337C28.1257 18.0337 29.8682 18.7271 31.2598 20.0801C32.6614 21.4428 33.3866 23.3028 33.3866 25.6219C33.3866 25.8152 33.2299 25.9719 33.0366 25.9719C32.8433 25.9719 32.6866 25.8152 32.6866 25.6219C32.6866 23.4584 32.0156 21.7912 30.7718 20.582C29.518 19.363 27.9537 18.7337 26.0556 18.7337C25.8623 18.7337 25.7056 18.577 25.7056 18.3837Z"
                                          fill="white"/>
                                    <path d="M27.3049 29.0385C27.3049 29.0385 27.8192 29.0752 28.0764 28.7446L28.5908 28.0832C28.848 27.7526 29.4358 27.5321 30.0604 27.8628C30.3911 28.0465 30.9789 28.4139 31.3463 28.7078C31.7505 29.0018 32.5588 29.6631 32.5588 29.6631C32.9629 29.9938 33.0364 30.4714 32.7792 30.9858C32.522 31.4634 32.1546 31.9043 31.677 32.3084C31.3096 32.6391 30.9422 32.786 30.5748 32.8595H30.4278C30.2808 32.8595 30.0971 32.8228 29.9502 32.786C29.3991 32.6391 28.4806 32.2349 26.9007 31.3899C25.9087 30.8388 25.0637 30.2509 24.3656 29.6998C23.9982 29.4059 23.594 29.0752 23.2266 28.6711L23.0797 28.5241C22.6755 28.12 22.3449 27.7526 22.0509 27.3852C21.4998 26.6871 20.912 25.8421 20.3609 24.8501C19.5158 23.307 19.1117 22.3884 18.9647 21.8006C18.928 21.6536 18.8912 21.5067 18.8912 21.323V21.176C18.928 20.8086 19.1117 20.4412 19.4423 20.0738C19.8465 19.6329 20.2874 19.2655 20.765 18.9716C21.2794 18.7144 21.757 18.7879 22.0877 19.192C22.0877 19.192 22.749 20.0003 23.0429 20.4045C23.3001 20.7719 23.6675 21.3597 23.888 21.6904C24.2186 22.2782 23.9982 22.9028 23.7043 23.16L23.0429 23.6744C22.7123 23.9316 22.749 24.4459 22.749 24.4459C22.749 24.4459 23.6675 28.0832 27.3049 29.0385Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_1335">
                                        <rect width="26.5334" height="28" fill="white" transform="translate(13 13)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a href="#">
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="52" y="52" width="52" height="52" rx="12" transform="rotate(-180 52 52)"
                                      fill="#015AB4"/>
                                <g clip-path="url(#clip0_0_1345)">
                                    <path d="M17.3763 27.6752L20.3613 35.1371L24.2474 31.2511L30.9102 36.5469L35.6016 15.4531L11.6016 25.45L17.3763 27.6752ZM28.7435 21.5051L21.3975 28.2055L20.4825 31.6539L18.7923 27.4275L28.7435 21.5051Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_1345">
                                        <rect width="24" height="24" fill="white" transform="translate(11.6016 14)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a href="#">
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="52" y="52" width="52" height="52" rx="12" transform="rotate(-180 52 52)"
                                      fill="#015AB4"/>
                                <g clip-path="url(#clip0_0_1349)">
                                    <path d="M19.5866 19.7209H14.7686C14.5547 19.7209 14.3815 19.8943 14.3815 20.108V35.5863C14.3815 35.8002 14.5547 35.9734 14.7686 35.9734H19.5866C19.8004 35.9734 19.9737 35.8002 19.9737 35.5863V20.108C19.9737 19.8943 19.8004 19.7209 19.5866 19.7209Z"
                                          fill="white"/>
                                    <path d="M17.1793 12.0264C15.4262 12.0264 14 13.451 14 15.2022C14 16.9541 15.4262 18.3793 17.1793 18.3793C18.931 18.3793 20.3561 16.954 20.3561 15.2022C20.3561 13.451 18.931 12.0264 17.1793 12.0264Z"
                                          fill="white"/>
                                    <path d="M31.8416 19.3363C29.9065 19.3363 28.476 20.1682 27.6084 21.1134V20.1081C27.6084 19.8943 27.4351 19.721 27.2213 19.721H22.6072C22.3934 19.721 22.2201 19.8943 22.2201 20.1081V35.5864C22.2201 35.8002 22.3934 35.9735 22.6072 35.9735H27.4147C27.6285 35.9735 27.8018 35.8002 27.8018 35.5864V27.9282C27.8018 25.3476 28.5028 24.3422 30.3017 24.3422C32.2608 24.3422 32.4165 25.954 32.4165 28.061V35.5865C32.4165 35.8003 32.5898 35.9736 32.8036 35.9736H37.6129C37.8268 35.9736 38 35.8003 38 35.5865V27.0964C38 23.2591 37.2683 19.3363 31.8416 19.3363Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_1349">
                                        <rect width="24" height="24" fill="white" transform="translate(14 12)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import LogoSemtax from "../assets/images/svg/LogoSemtax.vue";
import ContactsIcon from "../assets/images/svg/contactsIcon.vue";
import BurgerIcon from "../assets/images/svg/burgerIcon.vue";
import FeaturesItem1 from "../assets/images/svg/featuresItem1.vue";
import FeaturesItem2 from "../assets/images/svg/featuresItem2.vue";
import FeaturesItem3 from "../assets/images/svg/featuresItem3.vue";
import FeaturesItem4 from "../assets/images/svg/featuresItem4.vue";
import LangSelect from "./LangSelect.vue";
import ScrollToMixin from "../mixins/scroll-to.mixin";

export default {
    name: 'SiteHeader',
    components: {
        LangSelect,
        FeaturesItem4, FeaturesItem3, FeaturesItem2, FeaturesItem1, BurgerIcon, ContactsIcon, LogoSemtax
    },
    mixins: [
        ScrollToMixin
    ],
    data() {
        return {
            isMenuOpen: false
        }
    },
    methods: {
        menuHandler() {
            this.isMenuOpen = !this.isMenuOpen
        }
    }
}

</script>


<style lang="scss">
.header {
  width: 100%;
  background: #FFFFFF;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 0 28px;
  box-sizing: border-box;

  @media (max-width: 1079px) {
    padding: 0;
  }

  .lang {
    &-desktop {
      @media (max-width: 1079px) {
        display: none;
      }
    }

    &-mobile {
      display: none;
      background: rgba(0, 127, 255, 0.08);
      padding-right: 12px;

      .selected-mobile {
        color: #015AB4;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.28px;
      }

      @media (max-width: 1079px) {
        display: flex;
        gap: 8px;
      }
    }
  }


  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    position: relative;

    @media (max-width: 1079px) {
      padding: 10px;
    }
  }

  &-burger {
    display: none;

    @media (max-width: 1079px) {
      display: block;
    }
  }

  &-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &_close-lang {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .navigation {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      flex-wrap: wrap;
      margin-top: 18px;

      &_block {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
      }

      &--item {
        width: 100%;
        max-width: 50%;
        height: 134px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 5px 14px 0px rgba(39, 36, 171, 0.08);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .title {
          color: #000;
          text-align: center;
          font-family: 'Golos Text', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: -0.28px;
        }

        svg {
          max-width: 48px;
          max-height: 48px;
        }
      }
    }

    &_contacts {
      max-width: 100%;
      width: 100%;
      position: absolute;
      bottom: 24px;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      box-sizing: border-box;

      &_block {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &_socials {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
      }

      &-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
        align-items: center;
        color: #015AB4;
        font-family: 'Golos Text', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.28px;
      }
    }
  }

  &-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
  }

  &-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 28px;

    @media (max-width: 660px) {
      display: none;

    }

    &__item {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        color: #015AB4;
      }
    }
  }

  &-contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    cursor: pointer;
    padding: 4px 12px 4px 6px;
    box-sizing: border-box;
    transition: all .3s ease-in-out;

    span {
      color: #015AB4;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.28px;


      @media (max-width: 1079px) {
        display: none;

      }
    }

    &:hover {
      background: rgba(0, 127, 255, 0.08);
    }
  }
}
</style>
