<script>
import HeroImg from "../../assets/images/svg/HeroImg.vue";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToMixin from "../../mixins/scroll-to.mixin";

gsap.registerPlugin(ScrollTrigger);


export default {
    name: "HeroBlock",
    components: {HeroImg},
    mixins: [
        ScrollToMixin
    ],
    mounted() {
        this.heroBlockAnimate()
    },
    methods: {
        heroBlockAnimate() {
            gsap.from('.hero-block', {
                opacity: 0,
                y: 100,
                stagger: 1,
                duration: 1,
                ease: "circ"
            })
        }
    }
}

</script>

<template>
    <div id="hero" class="hero-block">
        <div class="container">
            <div class="hero-block_container">
                <div class="text">
                    <div class="text-title" v-html="$gettext('Комплексні рішення для вашого бізнесу')"/>
                    <div class="text-desc"
                         v-html="$gettext('Бухгалтерські, юридичні, кадрові <br> та фінансові послуги')"/>
                    <div @click="scrollToId('#contacts')" class="text-button">
                        {{ $gettext('Отримати консультацію') }}
                    </div>
                </div>
                <hero-img class="hero-block_img"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

.hero-block {
  padding: 34px 0 80px;
  box-sizing: border-box;

  @media (max-width: 1079px) {
    padding: 22px 0 32px;

    &_container {
      flex-direction: column-reverse;

      .hero-block_img {
        max-width: 300px;
        width: 100%;
        height: 195px;
      }
    }
  }

  &_container {
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    padding-left: 40px;
    box-sizing: border-box;

    @media (max-width: 1079px) {
      padding: 0;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      @media (max-width: 1079px) {
        align-items: center;
        margin-bottom: 0;
      }


      &-title {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: -1.12px;
        color: white;
        max-width: 560px;
        margin-bottom: 20px;

        @media (max-width: 1079px) {
          margin-top: 14px;
          font-size: 32px;
          line-height: 36px;
          letter-spacing: -0.64px;
          text-align: center;
          margin-bottom: 16px;
        }
      }

      &-desc {
        color: #ffffff;
        text-align: left;
        font: 400 24px/32px "Golos Text", sans-serif;
        position: relative;
        max-width: 560px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 28px;

        @media (max-width: 1079px) {
          margin-bottom: 24px;
          text-align: center;
          font-size: 16px;
          line-height: 22px;
        }
      }

      &-button {
        box-sizing: border-box;
        background: #ff6b2c;
        border-radius: 8px;
        padding: 16px 32px 16px 32px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        color: #ffffff;
        text-align: left;
        font: 500 16px/20px "Golos Text", sans-serif;
        align-items: center;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), var(--orange, #FF6B2C);
        }
      }
    }
  }
}

</style>
