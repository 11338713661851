<script>
export default {
    name: "FeaturesItem3"
}

</script>

<template>
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_0_301)">
            <path d="M48.8664 17.3896H23.1337C22.2794 17.3896 21.5868 16.6971 21.5868 15.8428C21.5868 14.9885 22.2794 14.2959 23.1337 14.2959H48.8664C49.7206 14.2959 50.4132 14.9885 50.4132 15.8428C50.4132 16.6971 49.7206 17.3896 48.8664 17.3896Z" fill="black"/>
            <path d="M70.4531 25.9673C71.3074 25.9673 72 25.2747 72 24.4204V15.8428C72 15.2175 71.6236 14.6537 71.046 14.4141L36.5929 0.118125C36.2133 -0.039375 35.7868 -0.039375 35.4071 0.118125L0.954 14.4141C0.376453 14.6537 0 15.2175 0 15.8428V24.4204C0 25.2747 0.692578 25.9673 1.54688 25.9673H5.86139V51.4652H4.54908C3.69478 51.4652 3.0022 52.1578 3.0022 53.0121V60.0428H1.54688C0.692578 60.0428 0 60.7354 0 61.5897V70.4531C0 71.3074 0.692578 72 1.54688 72H70.4531C71.3074 72 72 71.3074 72 70.4531V61.5897C72 60.7354 71.3074 60.0428 70.4531 60.0428H68.9978V53.0121C68.9978 52.1578 68.3052 51.4652 67.4509 51.4652H66.1386V25.9673H70.4531ZM3.09375 16.8757L36 3.22158L68.9062 16.8757V22.8735H64.5917H56.0142H15.9858H7.40827H3.09375V16.8757ZM53.1551 51.4652C52.3008 51.4652 51.6082 52.1578 51.6082 53.0121V60.0428H20.3918V53.0121C20.3918 52.1578 19.6992 51.4652 18.8449 51.4652H17.5327V25.9673H54.4673V51.4652H53.1551ZM8.95514 25.9673H14.439V51.4652H8.95514V25.9673ZM6.09595 54.559H7.40827H15.9858H17.298V60.0428H6.09595V54.559ZM68.9062 68.9062H3.09375V63.1365H4.54908H18.8449H53.1551H67.4509H68.9062V68.9062ZM54.702 60.0428V54.559H56.0142H64.5917H65.904V60.0428H54.702ZM63.0449 51.4652H57.561V25.9673H63.0449V51.4652Z" fill="#FF6B2C"/>
            <path d="M36 41.4582C34.4882 41.4582 33.2581 40.2282 33.2581 38.7163C33.2581 37.2045 34.4882 35.9744 36 35.9744C37.5119 35.9744 38.7419 37.2045 38.7419 38.7163C38.7419 39.5706 39.4345 40.2632 40.2888 40.2632C41.1431 40.2632 41.8357 39.5706 41.8357 38.7163C41.8357 36.034 40.0161 33.7701 37.5469 33.0903V31.5684C37.5469 30.7141 36.8543 30.0215 36 30.0215C35.1457 30.0215 34.4531 30.7141 34.4531 31.5684V33.0903C31.9839 33.7701 30.1644 36.034 30.1644 38.7163C30.1644 41.9341 32.7822 44.552 36 44.552C37.5119 44.552 38.7419 45.782 38.7419 47.2938C38.7419 48.8056 37.5119 50.0357 36 50.0357C34.4882 50.0357 33.2581 48.8056 33.2581 47.2938C33.2581 46.4395 32.5655 45.7469 31.7112 45.7469C30.8569 45.7469 30.1644 46.4395 30.1644 47.2938C30.1644 49.976 31.9839 52.24 34.4531 52.9197V54.4417C34.4531 55.296 35.1457 55.9886 36 55.9886C36.8543 55.9886 37.5469 55.296 37.5469 54.4417V52.9197C40.0161 52.24 41.8357 49.976 41.8357 47.2938C41.8357 44.076 39.2178 41.4582 36 41.4582Z" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_0_301">
                <rect width="72" height="72" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped>

</style>
