<template>
    <div class="lang" @click="showLangList = !showLangList" ref="dropdown">
        <span class="flag" v-html="flags[appStore.lang]"></span>
        <svg class="lang-desktop" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_24_10344)">
                <path d="M4.66666 6.66699L7.99999 10.0003L11.3333 6.66699H4.66666Z" fill="#015AB4"/>
            </g>
            <defs>
                <clipPath id="clip0_24_10344">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
        <div v-if="isMobile" class="selected-mobile">
            <span class="code">{{ selectedLang.code }}</span> - {{ selectedLang.name }}
        </div>
        <div class="lang--block" v-if="showLangList">
            <div v-for="(l, index) in appStore.langList" :key='index' class="item" @click="changeLang(l.code)"
                 v-show="l.code !== appStore.lang">
                <span class="flag" v-html="flags[l.code]"></span>
                <div>
                    <span class="code">{{ l.code }}</span> - {{ l.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {DEFAULT_LANG, DEFAULT_LANG_NAME, setLangCookie} from "../lang";
import Cookies from 'js-cookie';
import flags from "../language/flags";
import {useAppStore} from "../store/app";

export default {
    name: "LangSelect",
    components: {},
    setup() {
        const appStore = useAppStore();

        return {
            appStore
        }
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showLangList: false,
            showLangListMob: false,
            flags: flags
        }
    },
    computed: {
        selectedLang() {
            return this.appStore.langList.filter(item => item.code === this.appStore.lang)[0] ?? DEFAULT_LANG_NAME;
        },
    },
    mounted() {
        this.setLangFromParams();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
            if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.showLangList = false
            }
        },
        setLang(e) {
            this.appStore.lang = e
        },
        setLangFromParams() {
            const lang = this.$route?.params?.lang ?? DEFAULT_LANG;
            setLangCookie(lang);
            this.setLang(lang);
        },
        changeLang(langId) {
            this.showLangList = false;
            this.setLang(langId);
            this.$language.locale = langId;
            const oldLang = Cookies.get('lang');
            Cookies.set('lang', langId, {expires: 90});
            const currentLang = langId === DEFAULT_LANG ? '' : langId;

            if (oldLang === DEFAULT_LANG) {
                location.href = '/' + langId + location.pathname
                    .replace(oldLang, currentLang)
                    .replace('//', '/');
            } else {
                location.href = location.pathname
                    .replace(oldLang, currentLang)
                    .replace('//', '/');
            }
        },
    }
};
</script>

<style lang="scss">

.lang {
  padding: 4px 0 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background: rgba(0, 127, 255, 0.08);
  }

  .flag {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .code {
    text-transform: uppercase;
  }

  &--block {
    position: absolute;
    top: calc(100% + 15px);
    left: -9px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 36, 72, 0.24);
    display: flex;
    flex-direction: column;
    padding: 19px 16px;
    box-sizing: border-box;
    gap: 14px;
    width: 200px;

    @media (max-width: 1079px) {
      left: unset;
      right: 0;
      top: calc(100% + 4px);
      box-shadow: 0px 4px 24px 0px rgba(39, 36, 171, 0.16);
      min-width: 176px;
        width: unset;
    }

    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      font-family: 'Golos Text', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      transition: all .3s ease-in-out;

      &:hover {
        color: #015AB4;
      }
    }
  }

}
</style>
