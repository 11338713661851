<template>
    <site-header/>
    <div class="main-page">
        <hero-bg class="hero-bg"/>
        <hero-bg-mob class="hero-bg_mob" />
        <hero-block />
        <features-block />
        <banner-block />
        <services-block />
        <how-we-works />
        <reviews-block />
        <contact-form />
        <site-footer />
    </div>
</template>

<script>
import HeroBlock from "./partials/HeroBlock.vue";
import HeroBg from "../assets/images/svg/HeroBg.vue";
import HeroBgMob from "../assets/images/svg/HeroBgMob.vue";
import FeaturesBlock from "./partials/FeaturesBlock.vue";
import BannerBlock from "./partials/BannerBlock.vue";
import ServicesBlock from "./partials/ServicesBlock.vue";
import HowWeWorks from "./partials/HowWeWorks.vue";
import SiteFooter from "../components/SiteFooter.vue";
import ContactForm from "./partials/ContactForm.vue";
import SiteHeader from "../components/SiteHeader.vue";
import ReviewsBlock from "./partials/ReviewsBlock.vue";

export default {
    name: "MainPage",
    components: {
        ReviewsBlock,
        SiteHeader,
        ContactForm,
        SiteFooter, HowWeWorks, ServicesBlock, BannerBlock, FeaturesBlock, HeroBgMob, HeroBg, HeroBlock}
}

</script>

<style lang="scss">

.main-page {
    position: relative;
    background: #EEF4FA;

    .hero-bg {
        position: absolute;
        width: 100%;
        z-index: 0;
        top: 0;

        @media (max-width: 660px) {
            display: none;
        }
    }

    .hero-bg_mob {
        display: none;
        position: absolute;
        width: 100%;
        z-index: 0;
        top: 0;

        @media (max-width: 660px) {
            display: block;
        }
    }
}

</style>
