<script>
export default {
    name: "FeaturesItem2"
}

</script>

<template>
    <svg width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9387 16.2751C19.9895 16.2751 19.22 15.5055 19.22 14.5563V9.79102C19.22 8.8418 19.9895 8.07227 20.9387 8.07227H30.4694C31.4186 8.07227 32.1881 8.8418 32.1881 9.79102C32.1881 10.7402 31.4186 11.5098 30.4694 11.5098H22.6575V14.5563C22.6575 15.5055 21.888 16.2751 20.9387 16.2751Z" fill="#FF6B2C"/>
        <path d="M59.0613 16.2751C58.112 16.2751 57.3425 15.5055 57.3425 14.5563V11.5098H49.5306C48.5814 11.5098 47.8119 10.7402 47.8119 9.79102C47.8119 8.8418 48.5814 8.07227 49.5306 8.07227H59.0613C60.0105 8.07227 60.78 8.8418 60.78 9.79102V14.5563C60.78 15.5055 60.0105 16.2751 59.0613 16.2751Z" fill="#FF6B2C"/>
        <path d="M30.4694 63.9279H20.9387C19.9895 63.9279 19.22 63.1584 19.22 62.2092V57.4438C19.22 56.4946 19.9895 55.7251 20.9387 55.7251C21.888 55.7251 22.6575 56.4946 22.6575 57.4438V60.4904H30.4694C31.4186 60.4904 32.1881 61.2599 32.1881 62.2092C32.1881 63.1584 31.4186 63.9279 30.4694 63.9279Z" fill="#FF6B2C"/>
        <path d="M59.0613 63.9279H49.5306C48.5814 63.9279 47.8119 63.1584 47.8119 62.2092C47.8119 61.2599 48.5814 60.4904 49.5306 60.4904H57.3425V57.4438C57.3425 56.4946 58.112 55.7251 59.0613 55.7251C60.0105 55.7251 60.78 56.4946 60.78 57.4438V62.2092C60.78 63.1584 60.0105 63.9279 59.0613 63.9279Z" fill="#FF6B2C"/>
        <path d="M11.4083 30.5709C7.80751 30.5709 4.76532 27.6015 4.76532 24.0868C4.76532 20.5722 7.80751 17.6028 11.4083 17.6028C14.9836 17.6028 17.8924 20.5115 17.8924 24.0868C17.8924 27.6622 14.9836 30.5709 11.4083 30.5709ZM11.4083 21.0403C9.67079 21.0403 8.20282 22.4354 8.20282 24.0868C8.20282 25.7383 9.67079 27.1334 11.4083 27.1334C13.0881 27.1334 14.4549 25.7667 14.4549 24.0868C14.4549 22.407 13.0881 21.0403 11.4083 21.0403Z" fill="#FF6B2C"/>
        <path d="M20.9388 44.8668H1.71875C0.769531 44.8668 0 44.0973 0 43.1481C0 40.1452 1.20328 37.3156 3.38828 35.1798C5.55297 33.064 8.40109 31.8987 11.4083 31.8987C17.6111 31.8987 22.6575 36.9451 22.6575 43.1481C22.6575 44.0973 21.888 44.8668 20.9388 44.8668ZM3.63188 41.4293H19.0295C18.2445 37.9457 15.1255 35.3362 11.4083 35.3362C7.61531 35.3362 4.43281 37.9457 3.63188 41.4293Z" fill="#FF6B2C"/>
        <path d="M68.5917 30.5709C65.0164 30.5709 62.1077 27.6622 62.1077 24.0868C62.1077 20.5115 65.0164 17.6028 68.5917 17.6028C72.1925 17.6028 75.2347 20.5722 75.2347 24.0868C75.2347 27.6015 72.1925 30.5709 68.5917 30.5709ZM68.5917 21.0403C66.9119 21.0403 65.5452 22.407 65.5452 24.0868C65.5452 25.7667 66.9119 27.1334 68.5917 27.1334C70.3292 27.1334 71.7972 25.7383 71.7972 24.0868C71.7972 22.4354 70.3292 21.0403 68.5917 21.0403Z" fill="#FF6B2C"/>
        <path d="M78.2813 44.8668H59.0612C58.112 44.8668 57.3425 44.0973 57.3425 43.1481C57.3425 36.9451 62.3889 31.8987 68.5917 31.8987C71.5989 31.8987 74.4472 33.064 76.6117 35.1798C78.7967 37.3156 80 40.1452 80 43.1481C80 44.0973 79.2305 44.8668 78.2813 44.8668ZM60.9705 41.4293H76.3681C75.5672 37.9457 72.3847 35.3362 68.5917 35.3362C64.8745 35.3362 61.7555 37.9457 60.9705 41.4293Z" fill="#FF6B2C"/>
        <path d="M40 35.3362C35.111 35.3362 31.1333 31.3586 31.1333 26.4695C31.1333 21.5804 35.111 17.6028 40 17.6028C44.8891 17.6028 48.8667 21.5804 48.8667 26.4695C48.8667 31.3586 44.8891 35.3362 40 35.3362ZM40 21.0403C37.0064 21.0403 34.5708 23.4759 34.5708 26.4695C34.5708 29.4631 37.0064 31.8987 40 31.8987C42.9936 31.8987 45.4292 29.4631 45.4292 26.4695C45.4292 23.4759 42.9936 21.0403 40 21.0403Z" fill="black"/>
        <path d="M51.9133 54.3975H28.0867C27.1375 54.3975 26.368 53.628 26.368 52.6788V50.2961C26.368 42.7794 32.4833 36.6641 40 36.6641C47.5167 36.6641 53.632 42.7794 53.632 50.2961V52.6788C53.632 53.628 52.8625 54.3975 51.9133 54.3975ZM29.8055 50.96H50.1945V50.2961C50.1945 44.6747 45.6213 40.1016 40 40.1016C34.3788 40.1016 29.8055 44.6748 29.8055 50.2961V50.96Z" fill="black"/>
    </svg>
</template>

<style scoped>

</style>
