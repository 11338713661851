<script>

export default {
    name: "BannerLogoImg"
}
</script>

<template>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_0_3034" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
            <rect width="56" height="56" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_0_3034)">
            <path d="M46.4986 25.3489C46.4986 25.5926 46.4992 25.8303 46.5004 26.0625C46.4116 25.3115 46.2434 24.7262 45.9433 24.2154C45.6171 23.6603 45.1348 23.1931 44.4291 22.6963L39.8025 20.0251C39.011 19.5871 38.1292 19.0933 37.1459 18.5257C36.1628 17.9582 35.2945 17.4414 34.5196 16.9751L29.8914 14.3029C29.0873 13.9305 28.4277 13.7467 27.7663 13.7518C27.0937 13.7569 26.4189 13.9576 25.5876 14.3536L21.5117 16.7068C20.6279 17.2409 19.6159 17.8469 18.4402 18.5259C17.2644 19.2047 16.2336 19.7778 15.3294 20.2761L11.2524 22.63C10.4743 23.1655 9.95681 23.6614 9.61615 24.262C9.33278 24.7617 9.17193 25.3338 9.08582 26.0619C9.08685 25.8299 9.08758 25.5922 9.08738 25.3489C9.08743 24.3827 9.07778 23.5071 9.06486 22.7101L9.06493 16.8793C9.14512 16.0577 9.30821 15.4285 9.61634 14.8855C9.95689 14.2851 10.4744 13.7893 11.2526 13.2536L15.3298 10.8997C16.2341 10.4013 17.265 9.82827 18.4406 9.1494C19.6164 8.47064 20.6284 7.86442 21.512 7.33041L25.5879 4.97722C26.4191 4.58147 27.0941 4.38059 27.7667 4.37547C28.4281 4.37039 29.0876 4.55411 29.8917 4.92659L34.52 7.59871C35.2949 8.06507 36.1632 8.58181 37.1462 9.14939C38.1293 9.71698 39.0113 10.2107 39.803 10.6489L44.4297 13.3201C45.1353 13.8169 45.6177 14.284 45.9437 14.8391C46.2689 15.3924 46.4391 16.0333 46.5216 16.8772L46.5215 22.7286C46.5087 23.5208 46.4992 24.3903 46.4992 25.349" fill="#003BAF"/>
            <path d="M46.4986 25.3489C46.4986 25.5926 46.4992 25.8303 46.5004 26.0625C46.4116 25.3115 46.2434 24.7262 45.9433 24.2154C45.6171 23.6603 45.1348 23.1931 44.4291 22.6963L39.8025 20.0251C39.011 19.5871 38.1292 19.0933 37.1459 18.5257C36.1628 17.9582 35.2945 17.4414 34.5196 16.9751L29.8914 14.3029C29.0873 13.9305 28.4277 13.7467 27.7663 13.7518C27.0937 13.7569 26.4189 13.9576 25.5876 14.3536L21.5117 16.7068C20.6279 17.2409 19.6159 17.8469 18.4402 18.5259C17.2644 19.2047 16.2336 19.7778 15.3294 20.2761L11.2524 22.63C10.4743 23.1655 9.95681 23.6614 9.61615 24.262C9.33278 24.7617 9.17193 25.3338 9.08582 26.0619C9.08685 25.8299 9.08758 25.5922 9.08738 25.3489C9.08743 24.3827 9.07778 23.5071 9.06486 22.7101L9.06493 16.8793C9.14512 16.0577 9.30821 15.4285 9.61634 14.8855C9.95689 14.2851 10.4744 13.7893 11.2526 13.2536L15.3298 10.8997C16.2341 10.4013 17.265 9.82827 18.4406 9.1494C19.6164 8.47064 20.6284 7.86442 21.512 7.33041L25.5879 4.97722C26.4191 4.58147 27.0941 4.38059 27.7667 4.37547C28.4281 4.37039 29.0876 4.55411 29.8917 4.92659L34.52 7.59871C35.2949 8.06507 36.1632 8.58181 37.1462 9.14939C38.1293 9.71698 39.0113 10.2107 39.803 10.6489L44.4297 13.3201C45.1353 13.8169 45.6177 14.284 45.9437 14.8391C46.2689 15.3924 46.4391 16.0333 46.5216 16.8772L46.5215 22.7286C46.5087 23.5208 46.4992 24.3903 46.4992 25.349" fill="black" fill-opacity="0.2"/>
            <path d="M13.8123 21.152L11.2522 22.63C10.6093 23.0726 10.1441 23.4883 9.80978 23.9579C9.554 23.8949 9.30851 23.8012 9.07801 23.6803C9.07428 23.3446 9.06971 23.0213 9.06468 22.7101L9.06458 17.9381L9.0855 17.927C10.2153 19.5423 11.8963 20.689 13.8123 21.1517" fill="#002D85"/>
            <path d="M45.9987 24.3122C45.9809 24.2797 45.9628 24.2475 45.944 24.2155C45.6178 23.6604 45.1355 23.1931 44.43 22.6966L41.0833 20.7643C43.2626 20.8366 45.3197 19.7592 46.5013 17.9267L46.5218 17.9395L46.5219 22.7286C46.5151 23.1498 46.5091 23.5927 46.5052 24.0592C46.3433 24.1568 46.1741 24.2414 45.9989 24.3123" fill="#002D85"/>
            <path d="M9.56664 18.5485L9.56655 16.9038C9.62768 16.2924 9.74622 15.6731 10.0526 15.1331C10.3973 14.5252 10.9533 14.0701 11.5206 13.6783L15.5758 11.3371C16.6194 10.762 17.6589 10.1801 18.6909 9.58426C19.7231 8.98833 20.7467 8.37899 21.7668 7.76272L25.8209 5.42203C26.4291 5.13368 27.0883 4.883 27.7698 4.87758C28.4321 4.87256 29.0654 5.09921 29.6598 5.37283L34.2641 8.03116C35.1366 8.55641 36.0125 9.07497 36.8944 9.58418C37.7764 10.0934 38.6641 10.5928 39.5551 11.0859L44.158 13.7434C44.679 14.1116 45.1831 14.537 45.5101 15.0933C45.8331 15.643 45.9559 16.2745 46.0186 16.9017L46.0186 18.5765C45.7967 18.8384 45.5548 19.0793 45.2961 19.2984C44.9828 18.8916 44.5757 18.5596 44.158 18.2642L39.5551 15.6067C38.6641 15.1136 37.7766 14.6143 36.8944 14.105C36.0123 13.5957 35.1367 13.077 34.2643 12.5521L29.6598 9.89363C29.0655 9.61984 28.4322 9.3932 27.7699 9.39822C27.0883 9.40353 26.4292 9.65432 25.8209 9.94257L21.7667 12.2833C20.7466 12.8995 19.723 13.5089 18.6909 14.1046C17.6589 14.7006 16.6194 15.2823 15.5758 15.8574L11.5205 18.1988C11.0711 18.5093 10.6288 18.8595 10.2928 19.294C10.0356 19.0619 9.79317 18.8129 9.56667 18.548" fill="url(#paint0_linear_0_3034)"/>
            <path d="M46.4984 18.6401C46.4984 18.3965 46.4991 18.1588 46.5001 17.9265C46.4112 18.6773 46.2431 19.2628 45.943 19.7735C45.6167 20.3286 45.1344 20.7958 44.4289 21.2925L39.8024 23.9637C39.0109 24.4018 38.129 24.8954 37.1458 25.4632C36.1627 26.0309 35.2944 26.5475 34.5193 27.0139L29.8911 29.686C29.0869 30.0585 28.4274 30.2423 27.7661 30.2371C27.0934 30.2319 26.4186 30.0312 25.5872 29.6355L21.5114 27.2823C20.6277 26.7482 19.6158 26.142 18.44 25.4631C17.2642 24.7843 16.2334 24.2112 15.329 23.7129L11.2518 21.3589C10.4738 20.8234 9.95629 20.3275 9.61571 19.7271C9.33238 19.2274 9.17166 18.6552 9.08531 17.927C9.08636 18.1591 9.08709 18.3967 9.08701 18.6401C9.08706 19.6061 9.07745 20.4818 9.0644 21.2788L9.06442 22.7099L9.06451 27.1097C9.1446 27.9314 9.30793 28.5603 9.61598 29.1036C9.95646 29.7041 10.4742 30.1999 11.2521 30.7354L15.3293 33.0894C16.2337 33.5877 17.2644 34.1607 18.4401 34.8395C19.6159 35.5184 20.6278 36.1246 21.5115 36.6587L25.5873 39.0118C26.4187 39.4076 27.0935 39.6083 27.766 39.6134C28.4274 39.6184 29.087 39.4349 29.8912 39.0624L34.5194 36.3902C35.2945 35.9239 36.1627 35.4072 37.1459 34.8396C38.1293 34.2719 39.0109 33.7782 39.8025 33.3401L44.4292 30.669C45.1348 30.1721 45.617 29.705 45.9433 29.15C46.2684 28.5966 46.4386 27.956 46.5212 27.1121L46.5211 22.7284L46.521 21.2605C46.5081 20.4684 46.4987 19.5988 46.4987 18.6401" fill="#FF6B2C"/>
            <path d="M9.56784 22.5644L9.56785 27.0852C9.62886 27.6967 9.74744 28.3159 10.0539 28.8559C10.3985 29.4638 10.9544 29.9188 11.5217 30.3105L15.577 32.6518C16.6207 33.2271 17.6601 33.8086 18.6922 34.4045C19.7242 35.0004 20.748 35.6097 21.768 36.2262L25.822 38.5668C26.4302 38.8552 27.0894 39.1061 27.7708 39.1112C28.4331 39.1163 29.0665 38.8898 29.6606 38.616L34.2652 35.9576C35.1376 35.4326 36.0136 34.9138 36.8956 34.4046C37.7776 33.8954 38.6652 33.3959 39.5564 32.9028L44.1593 30.2453C44.6802 29.8771 45.1844 29.4517 45.5112 28.8954C45.8342 28.3457 45.957 27.7142 46.0197 27.087L46.0197 22.5662C45.957 23.1933 45.8341 23.825 45.5112 24.3745C45.1843 24.9306 44.6802 25.3563 44.1593 25.7245L39.5563 28.382C38.6652 28.8751 37.7779 29.3745 36.8958 29.8839C36.0139 30.3929 35.1379 30.9117 34.2655 31.4367L29.661 34.0952C29.0669 34.369 28.4333 34.5956 27.7713 34.5905C27.0898 34.5851 26.4305 34.3343 25.8222 34.0459L21.7682 31.7053C20.7482 31.089 19.7247 30.4796 18.6924 29.8836C17.6602 29.2877 16.6211 28.706 15.5772 28.1309L11.5218 25.7896C10.9546 25.3977 10.3987 24.9427 10.054 24.3349C9.74764 23.7948 9.62905 23.1756 9.56794 22.5642" fill="url(#paint1_linear_0_3034)"/>
            <path d="M28.5907 42.5418C28.3092 42.6035 28.0376 42.6332 27.7658 42.6313C27.512 42.6294 27.258 42.5995 26.9953 42.542C26.0568 42.4259 25.1768 42.1144 24.3072 41.7004L24.2006 41.6496L19.9968 39.2226L19.9713 39.2071C18.9697 38.6019 17.9647 38.0034 16.951 37.4182C15.9374 36.833 14.9168 36.2618 13.892 35.6972L13.8659 35.6829L9.66071 33.255L9.56338 33.188C9.39689 33.0734 9.23418 32.9563 9.07586 32.8357C9.07244 33.1242 9.06841 33.4032 9.06411 33.6729L9.06413 35.104L9.06422 39.5039C9.14431 40.3256 9.30764 40.9545 9.61569 41.4977C9.95617 42.0983 10.4738 42.594 11.2518 43.1296L15.329 45.4835C16.2334 45.9818 17.2641 46.5549 18.4398 47.2337C19.6155 47.9124 20.6275 48.5187 21.5112 49.0528L25.587 51.406C26.4183 51.8019 27.0932 52.0024 27.7659 52.0077C28.4273 52.0127 29.0869 51.8291 29.8911 51.4566L34.5193 48.7845C35.2944 48.3182 36.1626 47.8015 37.1457 47.2339C38.1291 46.6662 39.0109 46.1723 39.8023 45.7344L44.4289 43.0631C45.1347 42.5664 45.6167 42.0992 45.943 41.5442C46.2681 40.9908 46.4383 40.3501 46.5209 39.5062L46.5208 35.1225L46.5207 33.6546C46.5164 33.3905 46.5125 33.1176 46.5093 32.8356C46.3894 32.9269 46.2672 33.0162 46.1427 33.1038L46.0333 33.1808L41.2674 35.9324L41.2438 35.9455C40.3698 36.4291 39.4992 36.9189 38.6342 37.4183C37.7691 37.9176 36.9101 38.4265 36.0544 38.9416L36.0312 38.9555L31.2636 41.708L31.1423 41.7641C30.3138 42.1479 29.477 42.432 28.5907 42.5418Z" fill="#FF6B2C"/>
            <path d="M9.56652 34.9581L9.56653 39.4789C9.62755 40.0904 9.74623 40.7095 10.0526 41.2496C10.3972 41.8575 10.9533 42.3125 11.5205 42.7045L15.5757 45.0458C16.6196 45.6209 17.6588 46.2026 18.6909 46.7985C19.723 47.3944 20.7468 48.0037 21.7667 48.6202L25.8207 50.9608C26.429 51.2492 27.0882 51.4999 27.7696 51.5052C28.4318 51.5105 29.0652 51.2838 29.6594 51.01L34.264 48.3516C35.1364 47.8266 36.0124 47.3078 36.8943 46.7988C37.7763 46.2895 38.6638 45.7901 39.555 45.297L44.158 42.6395C44.6788 42.2712 45.1831 41.8459 45.5099 41.2895C45.8329 40.7398 45.9557 40.1083 46.0184 39.4812L46.0184 34.9604C45.9557 35.5875 45.8328 36.219 45.5099 36.7687C45.1829 37.325 44.6788 37.7504 44.1579 38.1187L39.555 40.7762C38.6638 41.2693 37.7765 41.7687 36.8945 42.2779C36.0127 42.7869 35.1367 43.3057 34.2642 43.8309L29.6597 46.4894C29.0656 46.7632 28.4321 46.9899 27.7699 46.9846C27.0883 46.9794 26.4293 46.7286 25.8209 46.4401L21.7669 44.0995C20.747 43.483 19.7233 42.8738 18.6911 42.2778C17.6588 41.6818 16.6197 41.1002 15.5759 40.5251L11.5205 38.1837C10.9532 37.7918 10.3974 37.3368 10.0527 36.7289C9.7464 36.1888 9.62781 35.5695 9.5667 34.9582" fill="url(#paint2_linear_0_3034)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_0_3034" x1="42.2113" y1="25.1708" x2="13.7458" y2="8.73622" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0056FF"/>
                <stop offset="1" stop-color="#0056FF" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_0_3034" x1="42.3901" y1="34.3015" x2="12.6911" y2="17.1548" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFAB2C"/>
                <stop offset="1" stop-color="#FF6B2C"/>
            </linearGradient>
            <linearGradient id="paint2_linear_0_3034" x1="42.3888" y1="46.6956" x2="12.6896" y2="29.5488" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFAB2C"/>
                <stop offset="1" stop-color="#FF6B2C"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<style scoped>

</style>
